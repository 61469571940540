import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "./Sidebar";
import Backdrop from "../../Elements/Backdrop";
// Assets
import BurgerIcon from "/blockchain/vmdata/openmarket/demo/ui/src/assets/BGV/svg/BurgerIcon.jsx";
import FujitsuLogo from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/fj-logo.png";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <a
            href="https://aet-blockchain.centralindia.cloudapp.azure.com/rclanding"
            target="_top"
            rel="noopener noreferrer"
            className="pointer flexNullCenter"
          >
            <img
              src={FujitsuLogo}
              alt="Fujitsu Logo"
              style={{ width: "110px", height: "auto" }}
            />
          </a>

          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="menu-item" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="menu-item" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                Services
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="menu-item" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                Users
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="menu-item" style={{ padding: "10px 15px" }} to="contacts" spy={true} smooth={true} offset={-80}>
                Contact
              </Link>
            </li>

          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer flexCenter">
              <a href="https://aet-blockchain.centralindia.cloudapp.azure.com" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Get Started
              </a>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }

  .menu-item {
    transition: all 0.3s ease;
  }

  .active {
    text-decoration: underline;
    color: #e91e63; /* Optional: color when active */
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

