import React from "react";
// Sections
import TopSellerNavbar from "../../components/Nav/Marketplace/TopSellerNavbar";
import Footer from "../../components/Sections/Marketplace/Footer"
import SellerDashboard from "../../components/Sections/Marketplace/SellerDashboard";

export default function Seller() {
  return (
    <>
      <TopSellerNavbar />
      <SellerDashboard />
      <Footer />
    </>
  );
}
