import React, { useState } from "react";
import styled from "styled-components";
import { Link } from 'react-scroll';
// Components
import FullButton from "../../Buttons/FullButton";
// Assets
import ContactImage from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Master/img/projects/footer-1.png";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    query: "",
  });

  const [popupVisible, setPopupVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://aet-blockchain.centralindia.cloudapp.azure.com:8004/contactUs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setPopupVisible(true);
        setTimeout(() => setPopupVisible(false), 2000); // Hide after 2 seconds
        setFormData({ name: "", email: "", query: "" }); // Reset form
      } else {
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Wrapper id="contacts">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <ImgWrapper>
                <img className="radius8" src={ContactImage} alt="Blockchain Solutions" />
              </ImgWrapper>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">Unlocking Blockchain Potential</h4>
              <h2 className="font40 extraBold">Blockchain Use Cases & Benefits</h2>
              <p className="font12">
                Blockchain technology is transforming industries worldwide by enabling greater transparency, security, and efficiency.
                Whether it's supply chain tracking, decentralized finance (DeFi), NFTs, or carbon footprint calculations,
                blockchain provides a secure, transparent, and immutable ledger for all transactions, ensuring trust and authenticity.
                Businesses are leveraging this innovative technology to streamline operations, enhance data security, and foster
                trust among stakeholders. From combating counterfeit products to enabling smart contracts.
              </p>
              <BtnWrapper>
                <Link
                  style={{ padding: "10px 15px", textDecoration: "none", border: "none" }} // Ensure no underline
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={-80}>
                  <FullButton title="Explore Usecases" />
                </Link>
              </BtnWrapper>
            </AddRight>
            <ContactFormContainer>
              <ContactForm onSubmit={handleSubmit} id="contactus">
                <h4 className="font30 bold">Contact Us</h4>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <textarea
                  name="query"
                  placeholder="Your Query"
                  rows="4"
                  value={formData.query}
                  onChange={handleChange}
                  required
                ></textarea>
                <SubmitButton type="submit">Submit</SubmitButton>
              </ContactForm>
              {popupVisible && <SuccessPopup>Submitted Successfully!!</SuccessPopup>}
            </ContactFormContainer>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const BtnWrapper = styled.div`
  margin-top: 12%;
  margin-left: -20px;
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 45px 0;
  display: flex;
  align-items: flex-start;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
`;

const AddLeft = styled.div`
  width: 33%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const AddRight = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContactFormContainer = styled.div`
  width: 33%;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  input,
  textarea {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  textarea {
    resize: none;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #eb189c;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #d81b60;
  }
`;

const SuccessPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  z-index: 1000;
`;
