import React from "react";
import { Helmet } from "react-helmet";
// Sections
import TopNavbar from "../../components/Nav/Inquiry/TopSimpleNavbar";
import Footer from "../../components/Sections/Marketplace/Footer";
import Inquiry from "../../components/Sections/Inquiry/Inquiry"

export default function Landing() {
  return (
    <>
      <Helmet>
        <title>AET | Inquiry </title>
        <meta name="description" content="Blockchain usecases" />
        <meta name="keywords" content="Blockchain, Traceability and Supply chain" />
        <meta name="fujitsu" content="Blockchain" />
      </Helmet>
      <TopNavbar />
      <Inquiry />
      <Footer />
    </>
  );
}


