import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import FujitsuLogo from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/fj-footer.png";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            {/* Logo Link */}
            <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/marketplacelanding" target="_top" rel="noopener noreferrer">
              <Logo src={FujitsuLogo} alt="Fujitsu Logo" />
            </a>
            <StyleP className="whiteColor font13">
              FUJITSU - {getCurrentYear()}<span className="white font13"> © </span> All Rights Reserved
            </StyleP>
            {/* Back to top link */}
            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

// Styled Components
const Wrapper = styled.div`
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const StyleP = styled.p`
  margin: 0;
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const Logo = styled.img`
  width: 110px;
  height: auto;
  object-fit: contain;
  background-color: transparent;
`;
