import React from "react";
import { Helmet } from "react-helmet";
// Sections
import TopNavbar from "../../components/Nav/BGV/TopNavbar";
import Header from "../../components/Sections/BGV/Header";
import Services from "../../components/Sections/BGV/Services";
import Projects from "../../components/Sections/BGV/Projects";
import Footer from "../../components/Sections/Marketplace/Footer"
import Contact from "../../components/Sections/BGV/Contact";

export default function Landing() {
  return (
    <>
      <Helmet>
        <title>BGV | Landing </title>
        <meta name="description" content="Blockchain usecases" />
        <meta name="keywords" content="Blockchain, Traceability and Supply chain" />
        <meta name="fujitsu" content="Blockchain" />
      </Helmet>
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
}


