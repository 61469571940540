import React from "react";
// Sections
import TopSellerNavbar from "../../components/Nav/Marketplace/TopSellerNavbar";
import Footer from "../../components/Sections/Marketplace/Footer"
import AdminDashboard from "../../components/Sections/Marketplace/AdminDashboard";

export default function Admin() {
  return (
    <>
      <TopSellerNavbar />
      <AdminDashboard />
      <Footer />
    </>
  );
}
