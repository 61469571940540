import React, { useState } from "react";
import styled from "styled-components";
// Components
import FullButton from "../../Buttons/FullButton";
// Assets
import ProjectImg1 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/RC/img/projects/header.webp";
import ProjectImg2 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Master/img/projects/id-project.jpg";
import ProjectImg3 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/ELF/img/projects/elf-header.jpg";
import ProjectImg4 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Master/img/projects/craft.png";
import ProjectImg5 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Master/img/projects/glovia.png";
import ProjectImg6 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Master/img/projects/smart-crop.avif";
import ProjectImg7 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Master/img/projects/footprint.webp";
import ProjectImg8 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Master/img/projects/bank-kyc.jpg";

export default function Projects() {
  const [showMore, setShowMore] = useState(false);

  const handleLoadMore = () => {
    setShowMore(true);
  };

  const handleShowLess = () => {
    setShowMore(false);
  };

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Explore Blockchain Usecases</h1>
            <p className="font13">
              Discover the transformative potential of blockchain technology. From ensuring transparency and trust to empowering communities, blockchain enables innovative solutions across various domains.
              <br />
              Explore how it can bring value through authenticity, accountability, and enhanced efficiency.
            </p>
          </HeaderInfo>

          {/* First Row */}
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <CardWrapper>
                <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/rclanding" target="_blank" rel="noopener noreferrer">
                  <CardImage src={ProjectImg1} alt="Royal Canin Project" />
                </a>
                <hr />
                <div>
                  <h3>Blockchain-based Track and Trace for Royal Canin</h3>
                  <p>
                    Introducing a cutting-edge solution for Royal Canin's premium cat and dog food products. Using blockchain technology, we ensure complete traceability and transparency in the supply chain.
                  </p>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/rclanding" target="_blank" rel="noopener noreferrer">
                    <FullButton title="Learn More" style={redButtonStyle} />
                  </a>
                </div>
              </CardWrapper>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <CardWrapper>
                <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/elflanding" target="_blank" rel="noopener noreferrer">
                  <CardImage src={ProjectImg3} alt="ELF Project" />
                </a>
                <hr />
                <div>
                  <h3>Blockchain-based Track and Trace for ELF</h3>
                  <p>
                    Introducing the blockchain technology-based solution for ELF Beauty Products. Using the blockchain technology, we ensure the complete traceability and transparency in the supply chain for ELF Beauty products.
                  </p>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/elflanding" target="_blank" rel="noopener noreferrer">
                    <FullButton title="Learn More" style={redButtonStyle} />
                  </a>
                </div>
              </CardWrapper>
            </div>

            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <CardWrapper>
                <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/bgvlanding" target="_blank" rel="noopener noreferrer">
                  <CardImage src={ProjectImg2} alt="Background Verification Project" />
                </a>
                <hr />
                <div>
                  <h3>Decentralized Identity for Background Verification</h3>
                  <p>
                    Our BGV platform securely enables the issuers to create the verifiable credentials and holders to manage them securely, and finally verifiers to authenticate them instantly on the blockchain.
                  </p>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/bgvlanding" target="_blank" rel="noopener noreferrer">
                    <FullButton title="Learn More" style={redButtonStyle} />
                  </a>
                </div>
              </CardWrapper>
            </div>
          </div>

          {/* Second Row - Show 4th box only when 'showMore' is true */}
          {/* Second Row */}
          <div className="row textCenter">
            {showMore && (
              <>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <CardWrapper>
                    <a href="https://aet-blockchain.centralindia.cloudapp.azure.com:3004/" target="_blank" rel="noopener noreferrer">
                      <CardImage
                        src={ProjectImg5}
                        alt="Glovia and Blockchain Integration"
                        style={{
                          maxHeight: '300px', // Adjust height
                          maxWidth: '100%',   // Ensure it fits within its container
                          objectFit: 'contain',
                          margin: '0 auto',  // Center the image horizontally
                        }}
                      />
                    </a>
                    <hr />
                    <div>
                      <h3>Blockchain Integration with GLOVIA</h3>
                      <p>
                        A blockchain-powered supply chain solution that ensures transparency, traceability, and trust across the entire product journey, from origin to consumer.
                      </p>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <a href="https://aet-blockchain.centralindia.cloudapp.azure.com:3004/" target="_blank" rel="noopener noreferrer">
                        <FullButton title="Learn More" style={redButtonStyle} />
                      </a>
                    </div>
                  </CardWrapper>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <CardWrapper>
                    <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/marketplacelanding" target="_blank" rel="noopener noreferrer">
                      <CardImage src={ProjectImg4} alt="Marketplace Project" />
                    </a>
                    <hr />
                    <div>
                      <h3>Blockchain and AI Enabled Open Marketplace for Rural Artisans</h3>
                      <p>
                        Our platform leverages blockchain and AI technologies to empower rural artisans by providing them with a secure and transparent marketplace.
                      </p>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/marketplacelanding" target="_blank" rel="noopener noreferrer">
                        <FullButton title="Learn More" style={redButtonStyle} />
                      </a>
                    </div>
                  </CardWrapper>
                </div>

                {/* New Card 1 */}
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <CardWrapper>
                    <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/">
                      <CardImage src={ProjectImg7} alt="Blockchain and IoT based Carbon Accounting and Tracking" />
                    </a>
                    <hr />
                    <div>
                      <h3>Blockchain and IoT based Carbon Accounting and Tracking</h3>
                      <p>
                        Combining blockchain and IoT to monitor and track carbon emissions in real-time, providing transparent, tamper-proof data for effective carbon accounting and sustainability initiatives.
                      </p>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/">
                        <FullButton title="Learn More" style={redButtonStyle} />
                      </a>
                    </div>
                  </CardWrapper>
                </div>

                {/* New Card 2 */}
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <CardWrapper>
                    <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/">
                      <CardImage src={ProjectImg6} alt="Smart Crop Insurane Uisng Blockchain and AI" />
                    </a>
                    <hr />
                    <div>
                      <h3>Smart Crop Insurance Using Blockchain and AI</h3>
                      <p>
                        Utilizing blockchain and AI to revolutionize crop insurance by ensuring accurate damage assessment, faster claim processing, and transparency in policy management, empowering farmers with reliable and efficient support.
                      </p>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/">
                        <FullButton title="Learn More" style={redButtonStyle} />
                      </a>
                    </div>
                  </CardWrapper>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <CardWrapper>
                    <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/">
                      <CardImage src={ProjectImg8} alt="Smart Crop Insurane Uisng Blockchain and AI" />
                    </a>
                    <hr />
                    <div>
                      <h3>Reusable KYC for Financial Services using Blockchain and AI</h3>
                      <p>
                        Reusable KYC for the financial services, powered by the Blockchain and AI, offers a secure and the efficient solution for the verification of the customer identities  and inoformation.
                      </p>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/">
                        <FullButton title="Learn More" style={redButtonStyle} />
                      </a>
                    </div>
                  </CardWrapper>
                </div>

              </>
            )}
          </div>

          {/* Load More / Show Less Button */}
          <div className="row flexCenter">
            <div style={{ margin: "30px 0", width: "200px" }}>
              {!showMore ? (
                <FullButton title="Load More Usecases" action={handleLoadMore} />
              ) : (
                <FullButton title="Show Less" action={handleShowLess} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const redButtonStyle = {
  padding: "10px 20px",
  fontSize: "14px",
  backgroundColor: "#e74c3c",
  color: "white",
  border: "none",
  borderRadius: "25px",
  transition: "all 0.3s ease",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};

const HeaderInfo = styled.div`
  text-align: center;
  margin-top: -90px;
  margin-bottom: 30px;
`;

const CardWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure all cards maintain uniform height */

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
  }

  hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 10px 0;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 250px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensures the images scale properly without distortion */
  border-radius: 8px;
  cursor: pointer;
`;

const Wrapper = styled.section`
  width: 100%;

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centers the cards in the row */
    gap: 20px; /* Adds spacing between cards */
    margin-bottom: 30px; /* Adds space between rows */
  }

  .col-xs-12 {
    flex: 1 1 calc(33.33% - 20px); /* Ensures three cards per row on larger screens */
    max-width: calc(33.33% - 20px);
  }

  @media (max-width: 768px) {
    .col-xs-12 {
      flex: 1 1 calc(50% - 20px); /* Two cards per row on medium screens */
      max-width: calc(50% - 20px);
    }
  }

  @media (max-width: 480px) {
    .col-xs-12 {
      flex: 1 1 100%; /* One card per row on small screens */
      max-width: 100%;
    }
  }
`;
