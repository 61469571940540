import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../../Buttons/FullButton";
// Assets
import { FaUserShield, FaUserAlt } from "react-icons/fa";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Blockchain User Roles</h1>
            <p className="font14 text-dark">
              Discover how ELF Beauty leverages blockchain technology to create a transparent and secure supply chain.<br />
              As an admin, you can add new products, ensuring seamless management and transparency. Customers can easily verify the authenticity of their purchased products using a QR code.
              This innovative system enhances trust and provides a secure, traceable purchasing experience for everyone.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <RoleWrapper>
                <FaUserShield size={80} color="#e70812" />
              </RoleWrapper>
              <BoxWrapper>
                <h5>Admin</h5>
                <p>Manage products, oversee the supply chain, and ensure transparency using blockchain.</p>
                <StyledFullButton
                  title="Admin Dashboard"
                  action={() => window.open("https://aet-blockchain.centralindia.cloudapp.azure.com:3001/", "_blank")}
                />
              </BoxWrapper>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <RoleWrapper>
                <FaUserAlt size={80} color="#e70812" />
              </RoleWrapper>
              <BoxWrapper>
                <h5>Customer</h5>
                <p>Buy the ELF Beauty products and verify the product authenticity with a simple QR code scan.</p>
                <StyledFullButton
                  title="Customer Portal"
                  action={() => window.open("https://aet-blockchain.centralindia.cloudapp.azure.com:3001/", "_blank")}
                />
              </BoxWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const StyledFullButton = styled(FullButton)`
  transform: scale(0.5);
`;


const Wrapper = styled.section`
  width: 100%;
  background-color: #f7f7f7;
  margin-top: -5%;
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 50px;

  h1 {
    color: #333;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    color: #555;
    font-size: 16px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 10px;
  }
`;

const BoxWrapper = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;

  h5 {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  p {
    color: #555;
    font-size: 14px;
    margin-bottom: 20px;
  }

  button {
    margin-top: 10px;
  }
`;

const RoleWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;

  h4 {
    margin-top: 15px;
    color: #333;
    font-size: 18px;
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;
