import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { FaInfoCircle, FaEye, FaEyeSlash } from "react-icons/fa";

// Global Styles
const GlobalStyle = createGlobalStyle`
  input:-webkit-autofill {
    -webkit-text-fill-color: #333333 !important;
    background-color: #f0f0f0 !important;
    color: #333333 !important;
  }

  input:-webkit-autofill::placeholder {
    color: #888888 !important;
  }
`;

export default function Register() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    role: "",
  });

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupType, setPopupType] = useState(""); // To handle popup type
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formHidden, setFormHidden] = useState(false); // Control form visibility on success
  const [passwordError, setPasswordError] = useState(""); // Track password error message
  const [emailError, setEmailError] = useState(""); // Track email error message

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword(formData.password)) {
      setPasswordError("Invalid password format. Click info icon.");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match!");
      return;
    }

    setPasswordError(""); // Clear any previous error if the validation passes

    try {
      const response = await fetch(
        "https://aet-blockchain.centralindia.cloudapp.azure.com:8004/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Hide the form immediately upon success
        setFormHidden(true);
        showPopup("Your account is being registered. Please wait.", "success");

        setTimeout(() => {
          window.location.href = "/openmp/login"; // Redirect to login
        }, 1000);
      } else {
        if (data.message && data.message.includes("Duplicate value detected")) {
          setEmailError("Email already taken!"); // Display email-specific error
        } else {
          showPopup("Please check your inputs.", "error");
        }
      }
    } catch (error) {
      showPopup("Please check your inputs.", "error");
    }
  };

  const showPopup = (message, type) => {
    setPopupMessage(message);
    setPopupType(type);
    setPopupVisible(true);
    setTimeout(() => setPopupVisible(false), 3000);
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper popupVisible={popupVisible}>
        {popupVisible && <Popup popupType={popupType}>{popupMessage}</Popup>}
        {!formHidden && ( // Hide form only on success
          <Container popupVisible={popupVisible}>
            <Header>
              <h1>Register</h1>
            </Header>
            <Form onSubmit={handleSubmit}>
              <Column>
                <InputWrapper>
                  <Label>Full Name</Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter your full name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    tabIndex="1"
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>Mobile</Label>
                  <Input
                    type="tel"
                    name="mobileNumber"
                    placeholder="Enter your mobile number"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    required
                    tabIndex="3"
                  />
                </InputWrapper>
              </Column>

              <Column>
                <InputWrapper>
                  <Label>
                    Email
                    {emailError && <EmailMessage>{emailError}</EmailMessage>} {/* Show email error beside the input */}
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    tabIndex="2"
                  />
                </InputWrapper>

                <InputWrapper>
                  <Label>User Role</Label>
                  <Select
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    required
                    tabIndex="4"
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    <option value="seller">Seller</option>
                    <option value="buyer">Buyer</option>
                  </Select>
                </InputWrapper>
              </Column>

              <Column>
                <InputWrapper>
                  <Label>
                    Password
                    <Tooltip>
                      <FaInfoCircle />
                      <TooltipText>
                        Password must be 9+ characters with at least one
                        uppercase, one lowercase, one number, and one special
                        character.
                      </TooltipText>
                    </Tooltip>
                  </Label>
                  <PasswordWrapper>
                    <Input
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                      tabIndex="5"
                    />
                    <TogglePasswordIcon
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {!passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </TogglePasswordIcon>
                  </PasswordWrapper>
                  {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>} {/* Show password error below the input */}
                </InputWrapper>
              </Column>

              <Column>
                <InputWrapper>
                  <Label>Confirm Password</Label>
                  <PasswordWrapper>
                    <Input
                      type={confirmPasswordVisible ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm your password"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      required
                      tabIndex="6"
                    />
                    <TogglePasswordIcon
                      onClick={() =>
                        setConfirmPasswordVisible(!confirmPasswordVisible)
                      }
                    >
                      {!confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                    </TogglePasswordIcon>
                  </PasswordWrapper>
                </InputWrapper>
              </Column>

              <Column>
                <InputWrapper>
                  <Button type="submit">Register</Button>
                </InputWrapper>
              </Column>

              <Column>
                <InputWrapper>
                  <CancelButton type="button">
                    <a
                      href="/openmp/register"
                      target="_top"
                      rel="noopener noreferrer"
                    >
                      Cancel
                    </a>
                  </CancelButton>
                </InputWrapper>
              </Column>
            </Form>
            <LoginLink>
              Already have an account?{" "}
              <a href="/openmp/login">Login here</a>
            </LoginLink>
          </Container>
        )}
      </Wrapper>
    </>
  );
}

// Styled Components

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 8px;
  color: #555555;
  font-weight: bold;
`;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 8px;
  color: #888888;
  cursor: pointer;

  &:hover span {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.85rem;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const TogglePasswordIcon = styled.div`
  position: absolute;
  right: 12px;
  font-size: 1rem;
  color: #888888;
  cursor: pointer;

  &:hover {
    color: #007bff;
  }
`;

const ErrorMessage = styled.div`
  color: #e74c3c;
  font-size: 0.8rem;
  margin-top: 5px;
`;

const EmailMessage = styled.div`
  color: #e74c3c;
  font-size: 0.8rem;
  margin-top: -2px;
  margin-left: 15px;
  font-weight:100;
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background: url('https://t3.ftcdn.net/jpg/05/66/34/10/360_F_566341036_f2mCzWyHi9I4aMOSSiy1XUUhvEqdUKJ1.jpg') no-repeat center center fixed;
  background-size: cover;
`;

const Container = styled.div`
  position: relative;
  z-index: 3;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 550px;
  display: ${({ popupVisible }) => (popupVisible ? "none" : "block")};
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) =>
    props.popupType === "error" ? "#e74c3c" : "#28a745"};
  color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  text-align: center;
  animation: fadeInOut 4s;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3px;

  h1 {
    font-size: 2rem;
    color: #333333;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-sizing: border-box;

  &::placeholder {
    font-size: 0.85rem;
    color: #888888;
  }

  &:focus {
    border-color: #8888ff;
    outline: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px 15px;
  font-size: 0.9rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-sizing: border-box;

  &:focus {
    border-color: #8888ff;
    outline: none;
  }
`;

const LoginLink = styled.div`
  text-align: center;
  margin-top: 10px;

  a {
    color: #F2B300;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Button = styled.button`
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #eb189c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Full width of the grid column */

  &:hover {
    background-color: #e70812;
  }
`;

const CancelButton = styled(Button)`
  background-color: grey;

  &:hover {
    background-color: #c0392b;
  }
`;
