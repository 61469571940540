import React from "react";
// Sections
import SimpleNavbar from "../../components/Nav/Marketplace/TopSimpleNavbar"; import Footer from "../../components/Sections/Marketplace/Footer"
import Register from "../../components/Sections/Marketplace/Register";

export default function UserRegister() {
  return (
    <>
      <SimpleNavbar />
      <Register />
      <Footer />
    </>
  );
}
