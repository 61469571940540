import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../../Elements/ProjectBox";
import FullButton from "../../Buttons/FullButton";
// Assets
import ProjectImg1 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/textile.jpg";
import ProjectImg2 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/ceramic.jpeg";
import ProjectImg3 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/wooden.jpg";
import ProjectImg4 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/bags.jpg";
import ProjectImg5 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/jewelry.jpg";
import ProjectImg6 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/metal.jpg";

export default function Projects() {

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Handcrafted Collections</h1>
            <p className="font13">
              Explore our curated collection of authentic handcrafted products. Each item reflects the skill, creativity, and cultural heritage of our artisans.
              <br />
              By supporting these projects, you empower rural communities and preserve traditional craftsmanship.
              Through the integration of Blockchain and AI , we ensure transparency, fair trade, and personalized recommendations to enhance your shopping experience.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Handwoven Textiles"
                text="Delicately crafted textiles showcasing traditional weaving techniques passed down through generations."
                action={() => alert("Handwoven Textiles clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Ceramic Pottery"
                text="Unique pottery pieces molded and painted by skilled hands, each telling its own story."
                action={() => alert("Ceramic Pottery clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Wooden Carvings"
                text="Intricate wooden carvings that bring traditional artistry to life with precision and care."
                action={() => alert("Wooden Carvings clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Eco-Friendly Bags"
                text="Handcrafted eco-friendly bags designed for sustainable living, made with love and care."
                action={() => alert("Eco-Friendly Bags clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Jewelry"
                text="Elegant handcrafted jewelry made from locally sourced materials, perfect for any occasion."
                action={() => alert("Jewelry clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Home Decor"
                text="Beautiful handcrafted home decor items that add warmth and tradition to your living spaces."
                action={() => alert("Home Decor clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "30px 0", width: "200px" }}>
              <FullButton title="Load More Projects" action={() => alert("Load More clicked")} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-top: -90px;
`;