import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import Sidebar from "./Sidebar";
import Backdrop from "../../Elements/Backdrop";
// Assets
import FujitsuLogo from "/blockchain/vmdata/openmarket/demo/ui/src/assets/BGV/img/projects/fj-logo.png";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <a
            href="https://aet-blockchain.centralindia.cloudapp.azure.com/marketplacelanding/"
            target="_top"
            rel="noopener noreferrer"
            className="pointer flexNullCenter"
          >
            <img
              src={FujitsuLogo}
              alt="Fujitsu Logo"
              style={{ width: "110px", height: "auto" }}
            />
          </a>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`


