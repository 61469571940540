import React from "react";
import styled from "styled-components";

export default function BuyerDashboard() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "1px 0" }}></div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Buyer Dashboard</h1>
          </HeaderInfo>
        </div>
        <div className="lightBg">
          <div className="container">
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

