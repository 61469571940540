import React, { useState } from "react";
import styled from "styled-components";
import { Link } from 'react-scroll';
// Components
import FullButton from "../../Buttons/FullButton";
// Assets
import ContactImage from "/blockchain/vmdata/openmarket/demo/ui/src/assets/BGV/img/projects/footer-1.png";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    query: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Query submitted by: ${formData.name}`);
  };

  return (
    <Wrapper id="contacts">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <ImgWrapper>
                <img className="radius8" src={ContactImage} alt="Royal Canin Supply Chain" />
              </ImgWrapper>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">Empowering Transparency</h4>
              <h2 className="font40 extraBold">Blockchain-Enabled Supply Chain</h2>
              <p className="font12">
                By tracking each RC product's journey, consumers can easily verify the authenticity of the products they purchase, ensuring they receive genuine Royal Canin products.
              </p>
              <br />
              <h3 className="font14 extraBold">Blockchain Track & Trace Benefits:</h3>
              <ul className="font12">
                <li>Enhanced Transparency: Every step of the product journey is recorded, ensuring visibility and authenticity.</li>
                <li>Improved Accountability: Blockchain ensures that all stakeholders in the supply chain can be held accountable for their actions.</li>
                <li>Failure : Reduced single point of failure.</li>
              </ul>
              <BtnWrapper>
                <Link
                  style={{
                    padding: "10px 15px",
                    textDecoration: "none", // Remove underline
                    marginTop: "10px",
                    border: "none", // Ensure no border
                  }}
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={-80}>
                  <FullButton title="Explore More" />
                </Link>
              </BtnWrapper>
            </AddRight>
            <ContactForm onSubmit={handleSubmit} id="contactus">
              <h4 className="font30 bold">Contact Us</h4>
              <input
                type="text"
                name="name"
                placeholder="Your Full Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email Address"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <textarea
                name="query"
                placeholder="Your Query"
                rows="4"
                value={formData.query}
                onChange={handleChange}
                required
              ></textarea>
              <SubmitButton type="submit">Submit</SubmitButton>
            </ContactForm>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const BtnWrapper = styled.div`
  margin-top: 2%;
  margin-left: -20px;
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 45px 0;
  display: flex;
  align-items: flex-start;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
`;

const AddLeft = styled.div`
  width: 33%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const AddRight = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 390px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContactForm = styled.form`
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  input,
  textarea {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  textarea {
    resize: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #eb189c;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #d81b60;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
