import React, { useRef, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import businessLogoUrl from '/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/building-logo.jpeg';
import userLogoUrl from '/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/user-logo.png';
import {
  FaCheckCircle,
  FaTimesCircle,
  FaStreetView,
  FaMapPin,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaEdit,
  FaSignOutAlt,
  FaBuilding,
  FaIndustry,
  FaPlus,
  FaTimes,
  FaBars,
  FaUserAlt,
  FaIdCard,
  FaStore,
  FaMapMarkedAlt,
  FaCity,
  FaUserCircle,
  FaSyncAlt,
  FaClock,
  FaSignInAlt,
  FaBoxOpen,
  FaArchive,
  FaBox,
  FaTag,
  FaMoneyBillWave,
  FaWarehouse,
  FaCalendarAlt,
  FaStar,
  FaShippingFast,
  FaFlag,
  FaBoxes
} from "react-icons/fa";
import { FaHandsClapping, FaShop } from "react-icons/fa6";
import Select from "react-select";
import { State, City } from "country-state-city";
import ReactTooltip from "react-tooltip";

export default function SellerDashboard() {
  const businessProfiles = JSON.parse(sessionStorage.getItem("businessProfiles")) || [];
  const hasApprovedBusiness = businessProfiles.some(business => business.status === "active");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [rotatingCards, setRotatingCards] = useState({});
  const [searchTerm, setSearchTerm] = useState(""); // State to store search input
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [editSelectedState, setEditSelectedState] = useState(null);
  const [editSelectedCity, setEditSelectedCity] = useState(null);
  const [selectedBusinessProfile, setSelectedBusinessProfile] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageBoxType, setMessageBoxType] = useState("success"); // "success" or "error"
  const [messageText, setMessageText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedMenu, setSelectedMenu] = useState("Business Information");
  const [showBusinessEditPopup, setShowBusinessEditPopup] = useState(false);
  const [showBusinessAddPopup, setshowBusinessAddPopup] = useState(false);
  const [showPersonalEditPopup, setShowPersonalEditPopup] = useState(false); // Separate popup for personal profile
  const [showUnarchivePopup, setShowUnarchivePopup] = useState(false);
  const [showArchivePopup, setShowArchivePopup] = useState(false);

  const stateOptions = State.getStatesOfCountry("IN").map((state) => ({
    label: state.name,
    value: state.isoCode,
  }));

  const addCityOptions = selectedState ? City.getCitiesOfState("IN", selectedState.value).map((city) => ({
    label: city.name,
    value: city.name,
  }))
    : [];

  const editCityOptions = editSelectedState ? City.getCitiesOfState("IN", editSelectedState.value).map((city) => ({
    label: city.name,
    value: city.name,
  }))
    : [];

  const [personalInfo, setPersonalInfo] = useState({
    fullName: "",
    email: "",
    phone: "",
  });

  const [businessFormData, setBusinessFormData] = useState({
    businessName: "",
    businessType: "",
    taxId: "",
    storeName: "",
    address: {
      state: "",
      city: "",
      zipCode: "",
      street: ""
    },
  });

  const resetBusinessForm = () => {
    setBusinessFormData({
      businessName: "",
      businessType: "",
      taxId: "",
      storeName: "",
      address: {
        state: "",
        city: "",
        zipCode: "",
        street: "",
      },
    });
    setSelectedState(null); // Reset the selected state
    setSelectedCity(null);  // Reset the selected city
    setShowErrors(false); // Clear any error messages
  };

  const businessTypeOptions = [
    { label: "Handwoven Textiles", value: "Handwoven Textiles" },
    { label: "Ceramic Pottery", value: "Ceramic Pottery" },
    { label: "Wooden Carvings", value: "Wooden Carvings" },
    { label: "Eco-Friendly Bags", value: "Eco-Friendly Bags" },
    { label: "Jewelry", value: "Jewelry" },
    { label: "Home Decor", value: "Home Decor" },
    { label: "Others", value: "Others" },
  ];

  const formatDate = (date) => {
    const options = {
      weekday: "short", year: "numeric", month: "short", day: "numeric",
      hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true,
    };
    return new Date(date).toLocaleString(undefined, options);
  };

  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref, callback]);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleRefreshClick = (businessName) => {
    setRotatingCards(prevState => ({
      ...prevState,
      [businessName]: true,  // Set rotation for this specific card
    }));

    fetchBusinessProfile();

    // Step 2: Reset rotation after animation completes for that specific card
    setTimeout(() => {
      setRotatingCards(prevState => ({
        ...prevState,
        [businessName]: false,  // Stop rotation for this card
      }));
    }, 500); // Duration of the rotation
  };

  const handlePersonalFormChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleBusinessFormChange = (event) => {
    const { name, value } = event.target;

    if (name === "state" || name === "city" || name === "zipCode" || name === "street") {
      setBusinessFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [name]: value,  // Dynamically update state, city, zipCode, or street
        },
      }));
    } else {
      setBusinessFormData((prevData) => ({
        ...prevData,
        [name]: value,  // Update other fields like businessName, taxId, etc.
      }));
    }
  };

  const handleLogout = () => {
    sessionStorage.clear(); // Clear all session storage
    window.location.href = "/openmp/login"; // Redirect to login page
  };

  const handlePersonalSaveChanges = async () => {
    const token = sessionStorage.getItem("authToken"); // Get bearer token from sessionStorage
    const updatedInfo = {
      name: personalInfo.fullName,
      email: personalInfo.email,
      mobileNumber: personalInfo.phone,
    };

    const originalInfo = JSON.parse(sessionStorage.getItem("userData")) || {};

    if (
      updatedInfo.name === originalInfo.name &&
      updatedInfo.email === originalInfo.email &&
      updatedInfo.mobileNumber === originalInfo.mobileNumber
    ) {
      setMessageBoxType("info");
      setMessageText("No changes made!!");
      setShowMessageBox(true); // Show info message
      setTimeout(() => {
        setShowMessageBox(false); // Hide the message
        window.location.reload(); // Reload the page to refresh the info card
      }, 2000); // Hide after 3 seconds
      return; // Exit without making the API call
    }

    try {
      const response = await fetch("https://aet-blockchain.centralindia.cloudapp.azure.com:8004/user", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add the bearer token
        },
        body: JSON.stringify(updatedInfo),
      });

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem("userData", JSON.stringify(data));
        setMessageBoxType("success");
        setMessageText("Saving data. Please wait!!");
        setShowMessageBox(true); // Show success message
        setTimeout(() => {
          setShowMessageBox(false); // Hide the message
          window.location.reload(); // Reload the page to refresh the info card
        }, 2000); // Hide after 3 seconds
      } else {
        setMessageBoxType("error");
        setMessageText("Session expired. Please log in again.");
        setShowMessageBox(true); // Show error message
        setTimeout(() => {
          setShowMessageBox(false); // Hide the message
          window.location.reload(); // Reload the page to refresh the info card
        }, 2000); // Hide after 3 seconds
      }

    } catch (error) {
      console.error('Error updating personal information:', error);
      setMessageBoxType("error");
      setMessageText("An error occurred while updating the information.");
      setShowMessageBox(true); // Show error message
      setTimeout(() => {
        setShowMessageBox(false); // Hide the message
        window.location.reload(); // Reload the page to refresh the info card
      }, 2000); // Hide after 3 seconds
    }
  };

  const handleBusinesAddChanges = async () => {
    const userId =
      JSON.parse(sessionStorage.getItem("userData"))?.id ||
      JSON.parse(sessionStorage.getItem("userData"))?._id;
    const token = sessionStorage.getItem("authToken");
    const apiUrl = `https://aet-blockchain.centralindia.cloudapp.azure.com:8004/sellers/${userId}/business`;

    const businessProfileData = {
      userId,
      businessName: businessFormData.businessName,
      businessType: businessFormData.businessType,
      taxId: businessFormData.taxId,
      storeName: businessFormData.storeName,
      address: {
        state: businessFormData.address.state,
        city: businessFormData.address.city,
        zipCode: businessFormData.address.zipCode,
        street: businessFormData.address.street,
      },
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(businessProfileData),
      });

      if (response.ok) {
        const result = await response.json();

        // Get existing profiles or initialize if none exists
        const existingProfiles =
          JSON.parse(sessionStorage.getItem("businessProfiles")) || [];

        // Append the new profile to the existing profiles
        existingProfiles.push(result);

        // Save updated profiles in sessionStorage
        sessionStorage.setItem("businessProfiles", JSON.stringify(existingProfiles));

        setMessageBoxType("success");
        setMessageText("Creating business profile. Please wait!!");
        setShowMessageBox(true);

        // Redirect to "Business Profile" tab after success
        setTimeout(() => {
          setShowMessageBox(false); // Hide the message box
          setshowBusinessAddPopup(false); // Close the form
          setBusinessProfile(existingProfiles); // Update UI with new profiles
          setSelectedMenu("Business Information"); // Redirect to "Business Profile" tab
        }, 2000);
      } else {
        const errorData = await response.json();

        // Clean up error messages dynamically
        const errorMessage = errorData?.message || "Failed to create business profile.";
        const cleanedMessage = errorMessage.replace(
          /Duplicate value detected for (\w+): .*/,
          (match, fieldName) => {
            const fieldMapping = {
              businessName: "Business Name",
              storeName: "Store Name",
              taxId: "TaxID",
            };

            return `Duplicate value detected for ${fieldMapping[fieldName] || fieldName}.`;
          }
        );

        setMessageBoxType("error");
        setMessageText(cleanedMessage); // Display cleaned-up error message
        setShowMessageBox(true);

        // Keep the form open, close the error message after 2 seconds
        setTimeout(() => {
          setShowMessageBox(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error creating business profile:", error);
      setMessageBoxType("error");
      setMessageText("An error occurred while creating the profile."); // Fallback error message
      setShowMessageBox(true);

      // Keep the form open, close the error message after 2 seconds
      setTimeout(() => {
        setShowMessageBox(false);
      }, 2000);
    }
  };

  const handleOpenBusinessPopup = () => {
    resetBusinessForm(); // Reset form state
    setshowBusinessAddPopup(true); // Open popup
  };

  const handleBusinessSaveChanges = async () => {
    const token = sessionStorage.getItem("authToken");
    const userId =
      JSON.parse(sessionStorage.getItem("userData"))?.id ||
      JSON.parse(sessionStorage.getItem("userData"))?._id;

    const businessId =
      selectedBusinessProfile?._id || selectedBusinessProfile?.id;

    if (!businessId) {
      setMessageBoxType("error");
      setMessageText("We couldn't find the business profile to update.");
      setShowMessageBox(true);

      setTimeout(() => {
        setShowMessageBox(false); // Hide error message
      }, 2000);
      return;
    }

    const updatedBusinessInfo = {
      businessName: businessFormData.businessName,
      businessType: businessFormData.businessType,
      taxId: businessFormData.taxId,
      storeName: businessFormData.storeName,
      address: {
        state: businessFormData.address.state,
        city: businessFormData.address.city,
        zipCode: businessFormData.address.zipCode,
        street: businessFormData.address.street,
      },
    };

    const isNoChange =
      JSON.stringify(updatedBusinessInfo) ===
      JSON.stringify({
        businessName: selectedBusinessProfile.businessName,
        businessType: selectedBusinessProfile.businessType,
        taxId: selectedBusinessProfile.taxId,
        storeName: selectedBusinessProfile.storeName,
        address: {
          state: selectedBusinessProfile.address.state,
          city: selectedBusinessProfile.address.city,
          zipCode: selectedBusinessProfile.address.zipCode,
          street: selectedBusinessProfile.address.street,
        },
      });

    if (isNoChange) {
      setMessageBoxType("error");
      setMessageText("No updates were made to your business profile.");
      setShowMessageBox(true);

      setTimeout(() => {
        setShowMessageBox(false); // Hide error message
      }, 2000);

      return;
    }

    try {
      const response = await fetch(
        `https://aet-blockchain.centralindia.cloudapp.azure.com:8004/sellers/${userId}/business/${businessId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedBusinessInfo),
        }
      );

      if (response.ok) {
        const updatedProfile = await response.json();

        // Update sessionStorage with the updated profile
        const existingProfiles =
          JSON.parse(sessionStorage.getItem("businessProfiles")) || [];
        const updatedProfiles = existingProfiles.map((profile) =>
          profile.id === businessId || profile._id === businessId
            ? updatedProfile
            : profile
        );
        sessionStorage.setItem("businessProfiles", JSON.stringify(updatedProfiles));

        // Show success message
        setMessageBoxType("success");
        setMessageText("Business profile is being updated. Please wait.");
        setShowMessageBox(true);

        setTimeout(() => {
          setShowMessageBox(false); // Hide success message
          setShowBusinessEditPopup(false); // Close the form
          setSelectedMenu("Business Information"); // Ensure the tab remains "Business Information"
          sessionStorage.setItem("activeTab", "Business Information");
          fetchBusinessProfile();

          // Clear the form data after the update is successful
          setBusinessFormData({
            businessName: "",
            businessType: "",
            taxId: "",
            storeName: "",
            address: {
              state: "",
              city: "",
              street: "",
              zipCode: "",
            },
          });
        }, 2000); // Delay the clearing of form data for 2 seconds
      } else {
        const errorResponse = await response.json();
        const errorMessage =
          errorResponse?.message || "Failed to update the business profile.";
        const cleanedMessage = errorMessage.replace(
          /Duplicate value detected for (\w+): .*/,
          (match, fieldName) => {
            // Transform field names for better readability
            const fieldMapping = {
              taxId: "TaxID",
              businessName: "Business Name",
            };

            return `Duplicate value detected for ${fieldMapping[fieldName] || fieldName}.`;
          }
        );

        setMessageBoxType("error");
        setMessageText(cleanedMessage);
        setShowMessageBox(true);

        setTimeout(() => {
          setShowMessageBox(false); // Hide error message
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating business profile:", error);
      setMessageBoxType("error");
      setMessageText(
        "We encountered an issue while updating your profile. Please try again later."
      );
      setShowMessageBox(true);

      setTimeout(() => {
        setShowMessageBox(false); // Hide error message
      }, 2000);
    }
  };

  const handleSellerArchiveChanges = async (selectedBusinessProfile) => {
    const token = sessionStorage.getItem("authToken");
    const businessId = selectedBusinessProfile?._id || selectedBusinessProfile?.id;

    if (!businessId) {
      console.error("Business ID not found.");
      return;
    }
    if (!token) {
      console.error("Auth Token missing.");
      return;
    }

    // Change the status based on the current status
    const newStatus =
      selectedBusinessProfile.status === "active"
        ? "archived"
        : selectedBusinessProfile.status === "archived"
          ? "pending"
          : "active";

    const body = {
      status: newStatus,
    };

    try {
      const response = await fetch(
        `https://aet-blockchain.centralindia.cloudapp.azure.com:8004/sellers/business/${businessId}/status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        // Show success message
        setMessageBoxType("success");
        setMessageText(
          `Business profile status has been changed to ${newStatus}.`
        );
        setShowMessageBox(true);

        // Optionally reload the page after 1 second
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.error("Failed to update business status:", response.statusText);
        setMessageBoxType("error");
        setMessageText("Failed to update the business profile status. Please try again.");
        setShowMessageBox(true);

        setTimeout(() => {
          setShowMessageBox(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating business status:", error);
      setMessageBoxType("error");
      setMessageText("An error occurred while updating the business profile status.");
      setShowMessageBox(true);

      setTimeout(() => {
        setShowMessageBox(false);
      }, 2000);
    }
  };

  const handleUnarchivePopupOpen = (businessProfile) => {
    setSelectedBusinessProfile(businessProfile);
    setShowUnarchivePopup(true);
  };

  const handleArchivePopupOpen = (businessProfile) => {
    setSelectedBusinessProfile(businessProfile);
    setShowArchivePopup(true);
  };

  const handleUnarchiveConfirm = () => {
    if (selectedBusinessProfile) {
      handleSellerArchiveChanges(selectedBusinessProfile);
    }
    setShowUnarchivePopup(false);
    setSelectedBusinessProfile(null);
  };

  const handleUnarchiveCancel = () => {
    setShowUnarchivePopup(false);
    setSelectedBusinessProfile(null);
  };

  const handleArchiveConfirm = () => {
    if (selectedBusinessProfile) {
      handleSellerArchiveChanges(selectedBusinessProfile);
    }
    setShowArchivePopup(false);
    setSelectedBusinessProfile(null);
  };

  const handleArchiveCancel = () => {
    setShowArchivePopup(false);
    setSelectedBusinessProfile(null);
  };


  const fetchBusinessProfile = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("authToken");

    if (!token) {
      console.error("Auth Token missing.");
      setLoading(false);
      return;
    }

    try {
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      const userId = userData?.id || userData?._id;

      if (!userId) {
        console.error("User ID missing.");
        setLoading(false);
        return;
      }

      const response = await fetch(
        `https://aet-blockchain.centralindia.cloudapp.azure.com:8004/sellers/${userId}/business`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch business profiles:", response.statusText);
        setBusinessProfile([]);  // Set empty array if fetch fails
        setLoading(false);
        return;
      }

      const data = await response.json();

      // Store the fetched data in sessionStorage
      sessionStorage.setItem('businessProfiles', JSON.stringify(data || []));

      // Set the fetched data to state
      setBusinessProfile(data || []);
    } catch (error) {
      console.error("Error fetching business profiles:", error);
      alert("Error fetching business profiles. Please check your connection.");
      setBusinessProfile([]);  // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  const validateAndSubmit = () => {
    // Check if all required fields are filled
    const isValid =
      businessFormData.businessName &&
      businessFormData.businessType &&
      businessFormData.taxId &&
      businessFormData.storeName &&
      businessFormData.address.state &&
      businessFormData.address.city &&
      businessFormData.address.zipCode &&
      businessFormData.address.street;
    if (!isValid) {
      // Show error messages
      setShowErrors(true);
      return;
    }

    // Proceed with form submission
    handleBusinesAddChanges();
    setShowErrors(false);
    setshowBusinessAddPopup(false); // Close the popup after successful submission
  };

  const handleEditBusinessProfile = (selectedBusinessProfile) => {
    // Set businessFormData from the selected profile for the form
    setBusinessFormData({
      businessName: selectedBusinessProfile.businessName,
      businessType: selectedBusinessProfile.businessType,
      taxId: selectedBusinessProfile.taxId,
      storeName: selectedBusinessProfile.storeName,
      address: {
        state: selectedBusinessProfile.address.state,
        city: selectedBusinessProfile.address.city,
        zipCode: selectedBusinessProfile.address.zipCode,
        street: selectedBusinessProfile.address.street,
      },
    });

    // Save the selected business profile into state
    setSelectedBusinessProfile(selectedBusinessProfile);

    setShowBusinessEditPopup(true);
  };

  useEffect(() => {
    const businessProfile = JSON.parse(sessionStorage.getItem("businessProfiles"));
    if (businessProfile)
      setBusinessFormData({
        businessName: businessProfile.businessName || "",
        businessType: businessProfile.businessType || "",
        taxId: businessProfile.taxId || "",
        storeName: businessProfile.storeName || "",
        address: {
          state: businessProfile.address?.state || "",
          city: businessProfile.address?.city || "",
          zipCode: businessProfile.address?.zipCode || "",
          street: businessProfile.address?.street || "",
        },
      });
  }, [businessProfile]);

  useEffect(() => {
    // Fetch user data from sessionStorage
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if (userData) {
      setPersonalInfo({
        fullName: userData.name || "",
        email: userData.email || "",
        phone: userData.mobileNumber || "",
      });
    }
  }, []);

  useEffect(() => {
    // Fetch business profile data on component mount
    fetchBusinessProfile();
  }, []);


  useOutsideClick(sidebarRef, () => setIsSidebarOpen(false));

  const renderContent = () => {
    if (selectedMenu === "Personal Information") {
      return (
        <CardContainer>
          {/* Personal Profile Section */}
          {sessionStorage.getItem("userData") ? (
            <Card>
              <CardHeader>
                <CardTitle>
                  <FaUserAlt />
                  Personal Profile
                </CardTitle>
                {personalInfo && personalInfo.fullName && personalInfo.email && personalInfo.phone && (
                  <EditButton onClick={() => setShowPersonalEditPopup(true)}>
                    <FaEdit />
                  </EditButton>
                )}
              </CardHeader>

              <CardContent style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '20px' }}>
                {personalInfo && personalInfo.fullName && personalInfo.email && personalInfo.phone ? (
                  <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    {/* Image on the left */}
                    <UserLogo
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        marginRight: '20px',
                        width: '100px',
                        height: '100px',
                        overflow: 'hidden',
                        borderRadius: '50%',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <img
                        src={userLogoUrl}
                        alt="User Logo"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          transition: 'transform 0.3s ease',
                        }}
                      />
                    </UserLogo>

                    {/* Content on the right */}
                    <InfoContainer style={{ flex: 1 }}>
                      <CardItem style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '10px' }}>
                        <CardIcon>
                          <FaUser />
                        </CardIcon>
                        <div>{personalInfo.fullName}</div>
                      </CardItem>
                      <CardItem style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '10px' }}>
                        <CardIcon>
                          <FaEnvelope />
                        </CardIcon>
                        <div>{personalInfo.email}</div>
                      </CardItem>
                      <CardItem style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '10px' }}>
                        <CardIcon>
                          <FaPhone />
                        </CardIcon>
                        <div>{personalInfo.phone}</div>
                      </CardItem>
                    </InfoContainer>
                  </div>
                ) : (
                  <NoData>
                    Session expired. Please <a href="/openmp/login" style={{
                      color: "#007bff", // Blue link color
                      textDecoration: "none", // Remove underline
                      fontWeight: "bold", // Make the text bold
                      padding: "5px 10px", // Add some padding for better spacing
                      border: "1px solid #007bff", // Add a border matching the link color
                      borderRadius: "5px", // Rounded corners
                      display: "inline-block", // Ensure proper padding effect
                      marginTop: "10px", // Add some space from the text above
                      transition: "all 0.3s ease", // Smooth hover effect
                    }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#007bff"; // Blue background on hover
                        e.target.style.color = "#fff"; // White text on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "transparent"; // Reset background
                        e.target.style.color = "#007bff"; // Reset text color
                      }}
                    >
                      log in
                    </a> again.
                  </NoData>
                )}
              </CardContent>
            </Card>
          ) : (
            <NoData>
              Session expired. Please <a href="/openmp/login" style={{
                color: "#007bff", // Blue link color
                textDecoration: "none", // Remove underline
                fontWeight: "bold", // Make the text bold
                padding: "5px 10px", // Add some padding for better spacing
                border: "1px solid #007bff", // Add a border matching the link color
                borderRadius: "5px", // Rounded corners
                display: "inline-block", // Ensure proper padding effect
                marginTop: "10px", // Add some space from the text above
                transition: "all 0.3s ease", // Smooth hover effect
              }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#007bff"; // Blue background on hover
                  e.target.style.color = "#fff"; // White text on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "transparent"; // Reset background
                  e.target.style.color = "#007bff"; // Reset text color
                }}
              >
                log in
              </a> again.
            </NoData>
          )}
        </CardContainer>

      );
    }

    if (selectedMenu === "Business Information") {

      // Check if businessProfile exists
      if (!businessProfile) {
        return (
          <NoData>
            Session expired. Please{" "}
            <a
              href="/openmp/login"
              style={{
                color: "#007bff",
                textDecoration: "none",
                fontWeight: "bold",
                padding: "5px 10px",
                border: "1px solid #007bff",
                borderRadius: "5px",
                display: "inline-block",
                marginTop: "10px",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#007bff";
                e.target.style.color = "#fff";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "#007bff";
              }}
            >
              log in
            </a>{" "}
            again.
          </NoData>
        );
      }

      // Filter business profiles based on the search term across multiple fields
      const filteredProfiles = businessProfile.filter((profile) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return (
          profile.businessName?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.storeName?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.businessType?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.address?.state?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.address?.city?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.address?.zipCode?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.address?.street?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.taxId?.toLowerCase().includes(lowerCaseSearchTerm)
        );
      });

      return (
        <div>
          {/* Search Box */}
          <div
            style={{
              margin: "20px 0",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <button
              onClick={() => handleOpenBusinessPopup()}
              style={{
                padding: "2px 20px",
                fontSize: "16px",
                color: "#fff",
                backgroundColor: "#495057",
                border: "1px solid #6c757d",
                borderRadius: "25px",
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "all 0.3s ease",
                marginRight: "20px",
                marginBottom: "10px",
                width: "auto",
                maxWidth: "250px",
                height: "43px",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#343a40";
                e.target.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#495057";
                e.target.style.boxShadow = "0 2px 6px rgba(0, 0, 0, 0.1)";
              }}
            >
              <FaPlus style={{ marginRight: "8px", color: "#ffc107" }} />
              Create Business Profile
            </button>

            <input
              type="text"
              placeholder="Search Business..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                padding: "12px 15px",
                fontSize: "16px",
                width: "52%",
                maxWidth: "400px",
                borderRadius: "30px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
                marginBottom: "10px",
                marginTop: "0",
              }}
            />
          </div>

          {/* Refresh Button */}
          <CardContainer>
            {filteredProfiles.length > 0 ? (
              filteredProfiles
                .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                .slice(0, 20)
                .map((businessProfile, index) => (
                  <Card key={index} style={businessProfile.isEditing ? { order: -1 } : {}}>
                    <CardHeader>
                      <CardTitle>
                        <FaBuilding />
                        {businessProfile.businessName ? (
                          <>
                            {businessProfile.businessName.split(" ")[0]} <br />
                            {businessProfile.businessName.split(" ")[1] || " "}
                            {businessProfile.businessName.split(" ")[2] || ""}
                          </>
                        ) : (
                          "Business Profile"
                        )}
                      </CardTitle>

                      <RefreshButton
                        className={rotatingCards[businessProfile.businessName] ? "rotating" : ""}
                        onClick={() => handleRefreshClick(businessProfile.businessName)}
                        disabled={businessProfile.status === "disabled"} // Disable button when status is disabled
                        style={{
                          cursor: businessProfile.status === "disabled" ? "not-allowed" : "pointer",
                          backgroundColor: businessProfile.status === "disabled" ? "#e0e0e0" : "#495057", // Same as previous color
                          color: businessProfile.status === "disabled" ? "#aaa" : "#fff", // Same as previous color
                        }}
                      >
                        <FaSyncAlt />
                      </RefreshButton>

                      <EditButton
                        onClick={() => handleEditBusinessProfile(businessProfile)}
                        disabled={businessProfile.status === "disabled" || businessProfile.status === "archived"} // Disable for "disabled", "pending", or "archived"
                        style={{
                          cursor:
                            businessProfile.status === "disabled" || businessProfile.status === "archived"
                              ? "not-allowed"
                              : "pointer", // Not-allowed cursor for "disabled", "pending", or "archived"
                          backgroundColor:
                            businessProfile.status === "disabled" || businessProfile.status === "archived"
                              ? "#e0e0e0" // Light gray for disabled, pending, or archived
                              : "#66bb6a", // Green for active status
                          color:
                            businessProfile.status === "disabled" || businessProfile.status === "archived"
                              ? "#aaa" // Gray text for disabled, pending, or archived
                              : "#fff", // White text for active status
                        }}
                      >
                        <FaEdit />
                      </EditButton>


                      <ArchiveButton
                        onClick={() =>
                          businessProfile.status === "archived"
                            ? handleUnarchivePopupOpen(businessProfile) // Open unarchive popup
                            : handleArchivePopupOpen(businessProfile) // Open archive popup
                        }
                        disabled={
                          businessProfile.status === "disabled" || // Disabled if status is "disabled"
                          (businessProfile.status !== "active" && businessProfile.status !== "archived") // Enabled only for "active" or "archived"
                        }
                        style={{
                          cursor:
                            businessProfile.status === "disabled" ||
                              (businessProfile.status !== "active" && businessProfile.status !== "archived")
                              ? "not-allowed"
                              : "pointer", // Cursor for invalid states
                          backgroundColor:
                            businessProfile.status === "disabled" ||
                              (businessProfile.status !== "active" && businessProfile.status !== "archived")
                              ? "#e0e0e0" // Gray for disabled
                              : businessProfile.status === "archived"
                                ? "#28a745" // Green for unarchive
                                : "#dc3545", // Red for archive
                          color:
                            businessProfile.status === "disabled" ||
                              (businessProfile.status !== "active" && businessProfile.status !== "archived")
                              ? "#aaa" // Gray text for disabled
                              : "#fff", // White text for enabled
                        }}
                      >
                        {businessProfile.status === "archived" ? (
                          <FaBoxOpen /> // Icon for unarchive
                        ) : (
                          <FaArchive /> // Icon for archive
                        )}
                      </ArchiveButton>
                    </CardHeader>

                    <CardContent>
                      <CompanyLogo>
                        <img src={businessLogoUrl} alt="Business Logo" />
                      </CompanyLogo>


                      {/* Tooltip Component */}

                      {/* Approved Status */}
                      {businessProfile.status === "active" && (
                        <ApprovedContainer data-tip="This business profile is approved and active." data-for="status-tooltip">
                          <FaCheckCircle />
                          Approved
                        </ApprovedContainer>
                      )}

                      {/* Pending Approval Status */}
                      {businessProfile.status?.toLowerCase() === "pending" && (
                        <ReversePendingApprovalContainer data-tip="This business profile is pending approval from admin." data-for="status-tooltip">
                          <FaTimesCircle />
                          Pending Approval
                        </ReversePendingApprovalContainer>
                      )}

                      {/* Disabled Status */}
                      {businessProfile.status?.toLowerCase() === "disabled" && (
                        <DisabledContainer data-tip="This business profile is currently disabled by admin." data-for="status-tooltip">
                          <FaTimesCircle />
                          Disabled
                        </DisabledContainer>
                      )}

                      {/* Archived Status */}
                      {businessProfile.status?.toLowerCase() === "archived" && (
                        <DisabledContainer data-tip="This business profile is archived and not active." data-for="status-tooltip">
                          <FaTimesCircle />
                          Archived
                        </DisabledContainer>
                      )}

                      {/* Tooltip Component */}

                      <InfoContainer>
                        <GridContainer>
                          <CardItem>
                            <CardIcon>
                              <FaBuilding />
                            </CardIcon>
                            <div>{businessProfile.businessName || "N/A"}</div>
                          </CardItem>
                          <CardItem>
                            <CardIcon>
                              <FaIndustry />
                            </CardIcon>
                            <div>{businessProfile.businessType || "N/A"}</div>
                          </CardItem>
                          <CardItem>
                            <CardIcon>
                              <FaIdCard />
                            </CardIcon>
                            <div>{businessProfile.taxId || "N/A"}</div>
                          </CardItem>
                          <CardItem>
                            <CardIcon>
                              <FaStore />
                            </CardIcon>
                            <div>{businessProfile.storeName || "N/A"}</div>
                          </CardItem>
                          <CardItem>
                            <CardIcon>
                              <FaMapMarkedAlt />
                            </CardIcon>
                            <div>{businessProfile.address.state || "N/A"}</div>
                          </CardItem>
                          <CardItem>
                            <CardIcon>
                              <FaCity />
                            </CardIcon>
                            <div>{businessProfile.address.city || "N/A"}</div>
                          </CardItem>
                          <CardItem>
                            <CardIcon>
                              <FaStreetView />
                            </CardIcon>
                            <div>{businessProfile.address.street || "N/A"}</div>
                          </CardItem>
                          <CardItem>
                            <CardIcon>
                              <FaMapPin />
                            </CardIcon>
                            <div>{businessProfile.address.zipCode || "N/A"}</div>
                          </CardItem>
                        </GridContainer>
                      </InfoContainer>
                    </CardContent>

                    <Badge>
                      <FaClock style={{ marginRight: "8px" }} />
                      Last Updated: {formatDate(businessProfile.updatedAt)}
                    </Badge>
                  </Card>
                ))
            ) : (
              <NoData>No business details found. Please create a Business Profile.</NoData>
            )}
            <ReactTooltip id="status-tooltip" place="top" type="dark" effect="solid" />
          </CardContainer>

        </div>
      );
    }

    if (selectedMenu === "Manage Inventory") {

      // Check if businessProfile exists
      if (!businessProfile) {
        return (
          <NoData>
            Session expired. Please{" "}
            <a
              href="/openmp/login"
              style={{
                color: "#007bff",
                textDecoration: "none",
                fontWeight: "bold",
                padding: "5px 10px",
                border: "1px solid #007bff",
                borderRadius: "5px",
                display: "inline-block",
                marginTop: "10px",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#007bff";
                e.target.style.color = "#fff";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "#007bff";
              }}
            >
              log in
            </a>{" "}
            again.
          </NoData>
        );
      }

      // Filter business profiles based on the search term across multiple fields
      const filteredProfiles = businessProfile.filter((profile) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return (
          profile.businessName?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.storeName?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.businessType?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.address?.state?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.address?.city?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.address?.zipCode?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.address?.street?.toLowerCase().includes(lowerCaseSearchTerm) ||
          profile.taxId?.toLowerCase().includes(lowerCaseSearchTerm)
        );
      });

      return (
        <div>
          {/* Search Box */}
          <div
            style={{
              margin: "20px 0",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <button
              onClick={() => handleOpenBusinessPopup()}
              style={{
                padding: "2px 20px",
                fontSize: "16px",
                color: "#fff",
                backgroundColor: "#495057",
                border: "1px solid #6c757d",
                borderRadius: "25px",
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "all 0.3s ease",
                marginRight: "20px",
                marginBottom: "10px",
                width: "auto",
                maxWidth: "250px",
                height: "43px",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#343a40";
                e.target.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#495057";
                e.target.style.boxShadow = "0 2px 6px rgba(0, 0, 0, 0.1)";
              }}
            >
              <FaPlus style={{ marginRight: "8px", color: "#ffc107" }} />
              Create Product
            </button>

            <input
              type="text"
              placeholder="Search Products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                padding: "12px 15px",
                fontSize: "16px",
                width: "52%",
                maxWidth: "400px",
                borderRadius: "30px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
                marginBottom: "10px",
                marginTop: "0",
              }}
            />
          </div>

          {/* Refresh Button */}
          <CardContainer
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "20px",
              padding: "20px",
              backgroundColor: "#f8f9fa",
              borderRadius: "8px",
            }}
          >
            {/* Dummy Product Card */}
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "15px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s ease, box-shadow 0.2s ease",
                cursor: "pointer",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)")
              }
            >
              {/* Header */}
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                  alignItems: "center",
                }}
              >
                <CardTitle style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <FaBox style={{ color: "#495057" }} />
                  <span style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>
                    Wooden Frame
                  </span>
                </CardTitle>
                <div style={{ display: "flex", gap: "10px" }}>
                  {/* Refresh Button */}
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#495057",
                      color: "#fff",
                      padding: "6px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    <FaSyncAlt />
                  </button>
                  {/* Edit Button */}
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#66bb6a",
                      color: "#fff",
                      padding: "6px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    <FaEdit />
                  </button>
                  {/* Archive Button */}
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#dc3545",
                      color: "#fff",
                      padding: "6px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    <FaArchive />
                  </button>
                </div>
              </CardHeader>
              {/* Content */}
              <CardContent
                style={{
                  display: "grid",
                  gap: "10px",
                  gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                }}
              >
                <CardItem>
                  <CardIcon>
                    <FaTag />
                  </CardIcon>
                  <div>Product ID: #12345</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaBoxes />
                  </CardIcon>
                  <div>Category:Wood</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaMoneyBillWave />
                  </CardIcon>
                  <div>Price: $999</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaWarehouse />
                  </CardIcon>
                  <div>Stock: 20</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaIndustry />
                  </CardIcon>
                  <div>Manufacturer: ABC Corp</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaCalendarAlt />
                  </CardIcon>
                  <div>Launch Date: Jan 1, 2025</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaStar />
                  </CardIcon>
                  <div>Rating: 4.5/5</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaShippingFast />
                  </CardIcon>
                  <div>Shipping: Free</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaFlag />
                  </CardIcon>
                  <div>Origin: India</div>
                </CardItem>
                <CardItem>
                  <CardIcon>
                    <FaCheckCircle />
                  </CardIcon>
                  <div>Status: Available</div>
                </CardItem>
              </CardContent>

              {/* Footer */}
              <Badge
                style={{
                  marginTop: "10px",
                  fontSize: "14px",
                  color: "#777",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaClock style={{ marginRight: "8px", color: "#495057" }} />
                Last Updated: Jan 23, 2025
              </Badge>
            </Card>
          </CardContainer>
        </div>
      );
    }


    return <p>Content for {selectedMenu}</p>;
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <HamburgerButton onClick={toggleSidebar}>
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </HamburgerButton>
        <Sidebar ref={sidebarRef} isOpen={isSidebarOpen}>
          <SidebarHeader>Seller Dashboard</SidebarHeader>

          {/* User Greeting Section */}
          <div style={{
            textAlign: "center",
            margin: "15px 0",
            fontSize: "20px",
            fontWeight: "600",
            color: "#FFD700",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "14px",
            padding: "14px 10px",
            borderRadius: "15px",
            background: "linear-gradient(135deg, #4A4A4A, #2D2D2D)",
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.3)",
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
          }} onMouseEnter={(e) => {
            e.target.style.transform = "scale(1.05)";
          }} onMouseLeave={(e) => {
            e.target.style.transform = "scale(1)";
          }}>
            <span style={{ fontWeight: "900", color: "#fff", fontSize: "20px" }}>
              Hello,
              <FaHandsClapping size={24} style={{ color: "#FFD700", marginRight: '10px' }} />
              {personalInfo.fullName
                ? personalInfo.fullName.split(' ')[0].charAt(0).toUpperCase() + personalInfo.fullName.split(' ')[0].slice(1).toLowerCase()
                : "User"
              }
            </span>

          </div>

          {/* Menu Items */}
          <MenuItem
            onClick={() => {
              setSelectedMenu("Business Information");
              setIsSidebarOpen(false); // Close the sidebar on menu item click
            }}
            active={selectedMenu === "Business Information"}
          >
            <FaBuilding /> Business Profile
          </MenuItem>

          {hasApprovedBusiness && (
            <MenuItem
              onClick={() => {
                setSelectedMenu("Manage Inventory");
                setIsSidebarOpen(false); // Close the sidebar on menu item click
              }}
              active={selectedMenu === "Manage Inventory"}
            >
              <FaShop /> Manage Inventory
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              setSelectedMenu("Personal Information");
              setIsSidebarOpen(false); // Close the sidebar on menu item click
            }}
            active={selectedMenu === "Personal Information"}
          >
            <FaUserCircle /> Personal Profile
          </MenuItem>

          {sessionStorage.getItem("userData") ? (
            // Logout Option
            <MenuItem
              onClick={() => {
                handleLogout();
                setIsSidebarOpen(false); // Close the sidebar on logout
              }}
            >
              <FaSignOutAlt /> Logout
            </MenuItem>
          ) : (
            // Login Option
            <MenuItem
              onClick={() => {
                window.location.href = "/openmp/login"; // Redirect to the login page
                setIsSidebarOpen(false); // Close the sidebar
              }}
            >
              <FaSignInAlt /> Login
            </MenuItem>
          )}


        </Sidebar>

        <Content>{renderContent()}</Content>

        {/* Global Message Box */}
        {showMessageBox && (
          <MessageBox type={messageBoxType}>
            {messageText}
          </MessageBox>
        )}

        {showPersonalEditPopup && (
          <Popup>
            <PopupContent>
              <PopupHeader>
                <h3>Edit Personal Profile</h3>
                <CloseButton onClick={() => setShowPersonalEditPopup(false)}>×</CloseButton>
              </PopupHeader>
              <form>
                <FormField>
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    value={personalInfo.fullName}
                    onChange={handlePersonalFormChange}
                  />
                </FormField>
                <FormField>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={personalInfo.email}
                    onChange={handlePersonalFormChange}
                  />
                </FormField>
                <FormField>
                  <label>Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    value={personalInfo.phone}
                    onChange={handlePersonalFormChange}
                  />
                </FormField>
                <FormActions>
                  <PopupButton type="button" onClick={handlePersonalSaveChanges}>
                    Save
                  </PopupButton>
                  <PopupButton cancel onClick={() => {
                    setShowPersonalEditPopup(false); // Close the popup

                    window.location.reload(); // Reload the page after 1 second

                  }}>
                    Cancel
                  </PopupButton>

                </FormActions>
              </form>
            </PopupContent>
          </Popup>
        )}

        {showBusinessAddPopup && (
          <Popup>
            <PopupContent>
              <PopupHeader>
                <h3 style={{ textAlign: "center", flex: 1, margin: 0 }}>
                  Create Business Profile
                </h3>
                <CloseButton onClick={() => setshowBusinessAddPopup(false)}>×</CloseButton>
              </PopupHeader>

              {/* Notice for mandatory fields */}
              <p style={{ color: "red", textAlign: "center", marginTop: "-14px", fontSize: "13px" }}>
                Fields marked with * are mandatory
              </p>

              {/* Form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validateAndSubmit(); // Trigger form validation and submission

                  // setTimeout(() => {
                  //   setSelectedMenu("Business Information"); // Redirect to "Manage Profile" tab after 2 seconds
                  // }, 2000); // 2000ms = 2 seconds
                }}
              >
                <FormContainer>
                  {/* Business Name */}
                  <FormField>
                    <label>
                      Business Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="businessName"
                      value={businessFormData.businessName}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Business Name"
                    />
                    {showErrors && !businessFormData.businessName && (
                      <ErrorMessage>Business Name is required</ErrorMessage>
                    )}
                  </FormField>

                  {/* Business Type */}
                  <FormField>
                    <label>
                      Business Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <StyledSelect
                      options={businessTypeOptions}
                      value={businessTypeOptions.find(
                        (option) => option.value === businessFormData.businessType
                      )}
                      onChange={(selectedOption) =>
                        handleBusinessFormChange({
                          target: { name: "businessType", value: selectedOption.value },
                        })
                      }
                      placeholder="Select Type"
                    />
                    {showErrors && !businessFormData.businessType && (
                      <ErrorMessage>Business Type is required</ErrorMessage>
                    )}
                  </FormField>

                  {/* Tax ID */}
                  <FormField>
                    <label>
                      Tax ID <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="taxId"
                      value={businessFormData.taxId}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Tax ID"
                    />
                    {showErrors && !businessFormData.taxId && (
                      <ErrorMessage>Tax ID is required</ErrorMessage>
                    )}
                  </FormField>

                  {/* Store Name */}
                  <FormField>
                    <label>
                      Store Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="storeName"
                      value={businessFormData.storeName}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Store Name"
                    />
                    {showErrors && !businessFormData.storeName && (
                      <ErrorMessage>Store Name is required</ErrorMessage>
                    )}
                  </FormField>

                  {/* Address Fields */}
                  {/* State */}
                  <FormField>
                    <label>
                      State <span style={{ color: "red" }}>*</span>
                    </label>
                    <StyledSelect
                      options={stateOptions}
                      value={selectedState} // Controlled value
                      onChange={(selectedOption) => {
                        setSelectedState(selectedOption);
                        setSelectedCity(null); // Reset city when state changes
                        handleBusinessFormChange({
                          target: {
                            name: "state",
                            value: selectedOption ? selectedOption.label : "",
                          },
                        });
                      }}
                      placeholder="Select State"
                    />
                    {showErrors && !businessFormData.address.state && (
                      <ErrorMessage>State is required</ErrorMessage>
                    )}
                  </FormField>

                  <FormField>
                    <label>
                      City <span style={{ color: "red" }}>*</span>
                    </label>
                    <StyledSelect
                      options={addCityOptions}
                      value={selectedCity} // Controlled value
                      onChange={(selectedOption) => {
                        setSelectedCity(selectedOption);
                        handleBusinessFormChange({
                          target: {
                            name: "city",
                            value: selectedOption ? selectedOption.label : "",
                          },
                        });
                      }}
                      placeholder="Select City"
                    />
                    {showErrors && !businessFormData.address.city && (
                      <ErrorMessage>City is required</ErrorMessage>
                    )}
                  </FormField>


                  {/* Street */}
                  <FormField>
                    <label>
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="street"
                      value={businessFormData.address.street}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Address"
                    />
                    {showErrors && !businessFormData.address.street && (
                      <ErrorMessage>Street Name is required</ErrorMessage>
                    )}
                  </FormField>

                  {/* Zipcode */}
                  <FormField>
                    <label>
                      Zipcode <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="zipCode"
                      value={businessFormData.address.zipCode}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Zipcode"
                    />
                    {showErrors && !businessFormData.address.zipCode && (
                      <ErrorMessage>Zipcode is required</ErrorMessage>
                    )}
                  </FormField>
                </FormContainer>

                <FormActions>
                  <PopupButton type="submit">Create</PopupButton>
                  <PopupButton cancel onClick={() => setshowBusinessAddPopup(false)}>
                    Cancel
                  </PopupButton>
                </FormActions>
              </form>
            </PopupContent>
          </Popup>

        )}

        {showBusinessEditPopup && (
          <Popup>
            <PopupContent>
              <PopupHeader>
                <h3 style={{ textAlign: "center", flex: 1, margin: 0 }}>
                  Edit Business Profile
                </h3>
                <CloseButton onClick={() => setShowBusinessEditPopup(false)}>×</CloseButton>
              </PopupHeader>

              {/* Form */}
              <form>
                <FormContainer>
                  <FormField>
                    <label>Business Name</label>
                    <input
                      type="text"
                      name="businessName"
                      value={businessFormData.businessName}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Business Name"
                    />
                  </FormField>

                  <FormField>
                    <label>Business Type</label>
                    <StyledSelect
                      options={[
                        { label: "Handwoven Textiles", value: "Handwoven Textiles" },
                        { label: "Ceramic Pottery", value: "Ceramic Pottery" },
                        { label: "Wooden Carvings", value: "Wooden Carvings" },
                        { label: "Eco-Friendly Bags", value: "Eco-Friendly Bags" },
                        { label: "Jewelry", value: "Jewelry" },
                        { label: "Home Decor", value: "Home Decor" },
                      ]}
                      value={{
                        label: businessFormData.businessType,
                        value: businessFormData.businessType,
                      }}
                      onChange={(selectedOption) =>
                        handleBusinessFormChange({
                          target: { name: "businessType", value: selectedOption.value },
                        })
                      }
                      placeholder="Select Business Type"
                    />
                  </FormField>

                  <FormField>
                    <label>Tax ID</label>
                    <input
                      type="text"
                      name="taxId"
                      value={businessFormData.taxId}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Tax ID"
                    />
                  </FormField>

                  <FormField>
                    <label>Store Name</label>
                    <input
                      type="text"
                      name="storeName"
                      value={businessFormData.storeName}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Store Name"
                    />
                  </FormField>

                  <FormField>
                    <label>State</label>
                    <StyledSelect
                      options={stateOptions}
                      value={editSelectedState || { label: businessFormData.address?.state, value: businessFormData.address?.state }}
                      onChange={(selectedOption) => {
                        setEditSelectedState(selectedOption);
                        setEditSelectedCity(null);
                        handleBusinessFormChange({
                          target: { name: 'state', value: selectedOption.label },
                        });
                      }}
                      placeholder="Select State"
                    />
                  </FormField>

                  <FormField>
                    <label>City</label>
                    <StyledSelect
                      options={editCityOptions}
                      value={editSelectedCity || { label: businessFormData.address?.city, value: businessFormData.address?.city }}
                      onChange={(selectedOption) => {
                        setEditSelectedCity(selectedOption);
                        handleBusinessFormChange({
                          target: { name: 'city', value: selectedOption.label },
                        });
                      }}
                      placeholder="Select City"
                    />
                  </FormField>

                  <FormField>
                    <label>Address</label>
                    <input
                      type="text"
                      name="street"
                      value={businessFormData.address.street}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Address"
                    />
                  </FormField>

                  <FormField>
                    <label>Zipcode</label>
                    <input
                      type="text"
                      name="zipCode"
                      value={businessFormData.address.zipCode}
                      onChange={handleBusinessFormChange}
                      placeholder="Enter Zipcode"
                    />
                  </FormField>
                </FormContainer>

                <FormActions>
                  <PopupButton
                    type="button"
                    onClick={handleBusinessSaveChanges}
                  >
                    Save
                  </PopupButton>
                  <PopupButton cancel onClick={() => setShowBusinessEditPopup(false)}>
                    Cancel
                  </PopupButton>
                </FormActions>
              </form>
            </PopupContent>
          </Popup>
        )}

        {showArchivePopup && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                background: "#fff",
                padding: "20px",
                width: "350px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                textAlign: "center",
              }}
            >
              <h3 style={{ marginBottom: "10px" }}>Confirm Archive</h3>
              <p style={{ marginBottom: "20px" }}>
                Are you sure you want to archive the business profile for{" "}
                <span style={{ fontWeight: "bold", color: "#e74c3c" }}>
                  {selectedBusinessProfile?.businessName || "this business"}
                </span>?
              </p>
              <button
                onClick={handleArchiveConfirm}
                style={{
                  margin: "5px",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  background: "#d9534f",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                Archive
              </button>
              <button
                onClick={handleArchiveCancel}
                style={{
                  margin: "5px",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  background: "#ccc",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {showUnarchivePopup && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                background: "#fff",
                padding: "20px",
                width: "350px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                textAlign: "center",
              }}
            >
              <h3 style={{ marginBottom: "10px" }}>Confirm Unarchive</h3>
              <p style={{ marginBottom: "20px" }}>
                Are you sure you want to unarchive the business profile for{" "}
                <span style={{ fontWeight: "bold", color: "#e74c3c" }}>
                  {selectedBusinessProfile?.businessName || "this business"}
                </span>?
              </p>
              <button
                onClick={handleUnarchiveConfirm}
                style={{
                  margin: "5px",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  background: "#d9534f",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                Unarchive
              </button>
              <button
                onClick={handleUnarchiveCancel}
                style={{
                  margin: "5px",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  background: "#ccc",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

      </Wrapper>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  input:-webkit-autofill {
    -webkit-text-fill-color: #333333 !important;  /* Set text color to dark */
    background-color: #f0f0f0 !important;        /* Lighter background for autofill */
    color: #333333 !important;                   /* Ensure the text color is dark */
      height: unset !important;
  }

  input:-webkit-autofill::placeholder {
    color: #888888 !important;   /* Set placeholder text color */
  }
`;

const StyledSelect = styled(Select)`
  .react-select__control {
    width: 100%;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .react-select__menu {
    z-index: 10;
  }
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 12px; /* Space between columns */
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
`;

const MessageBox = styled.div`
  background-color: ${(props) =>
    props.type === "success" ? "green" :
      props.type === "error" ? "red" :
        props.type === "info" ? "red" : "gray"};
  color: white;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 300px;
  text-align: center;
  border-radius: 5px;
  z-index: 9999;
  font-size: 16px;
`;

const CardContainer = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default to 2 columns for larger screens */
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr); /* Single column for tablets and smaller screens */
    gap: 20px;
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Stack cards for small mobile screens */
    gap: 10px;
    padding: 0 10px;
  }
`;

const Card = styled.div`
  background: #fff;
  position: relative;
  border-radius: 20px; /* Slightly rounded corners for a more modern feel */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Softer, larger shadow for depth */
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  background: linear-gradient(145deg, #f1f1f1, #e0e0e0); /* Subtle gradient background for the card */

  &:hover {
    transform: translateY(-8px); /* Slight lift on hover */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Larger shadow for hover effect */
    background: linear-gradient(145deg, #ffffff, #f4f4f4); /* Hover effect with subtle color change */
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Adjust max-width for medium screens */
  }

  @media (max-width: 480px) {
    max-width: 100%; /* Full width on very small screens */
    margin: 10px 0; /* Reduce margin for better spacing */
  }
`;

const CardContent = styled.div`
  padding: 5px 30px;
  background-color: #ffffff; /* Clean white background for content */
  display: flex;
  flex-direction: column;
  gap: 1px;
  flex: 1; 
  color: #666; /* Softer text color for content */
`;

const CardHeader = styled.div`
  padding: 15px;
  background-color: #bde4e85e; /* Soft background color for the header */
  border-bottom: 1px solid #ddd; /* Border to separate the header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px 20px 0 0; /* Round top corners for the header */
`;

const CardTitle = styled.h3`
  font-size: 19px;
  font-weight: 600;
  color: #333; /* Darker text for readability */
  margin: 0;
  display: flex;
  align-items: center; /* Align icon and text at the same height */
  gap: 10px; /* Space between icon and text */
  word-wrap: break-word; /* Prevents overflow for long titles */
  line-height: 1.2; /* Adjust line height for better readability */

  svg {
    flex-shrink: 0; /* Prevent icon from resizing */
    font-size: 1.5em; /* Adjust icon size to match text height */
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    font-size: 16px; /* Smaller font size for tablets */
    svg {
      font-size: 1.3em; /* Adjust icon size */
    }
  }

  @media (max-width: 480px) {
    font-size: 14px; /* Smallest font size for mobile */
    svg {
      font-size: 1.2em; /* Adjust icon size */
    }
  }
`;

const CardItem = styled.div`
 display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
`;

const CardIcon = styled.div`
font-size: 18px;
 color: #007bff; /* Vibrant color for icons */
`;

const EditButton = styled.button`
  background-color: #66bb6a; /* Subtle green */
  color: white;
  border: none;
  padding: 10px 15px; /* Comfortable padding for a sleek look */
  border-radius: 30px; /* Rounded corners for an elegant touch */
  cursor: pointer;
  margin-right: -5px;
  font-size: 14px;
  font-weight: 500; /* Semi-bold for readability */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #81c784; /* Lighter shade on hover */
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(1px); /* Slight push when clicked */
  }
`;

const ArchiveButton = styled.button`
  background-color: #e57373; /* Softer red */
  color: white;
  border: none;
  padding: 10px 15px; /* Comfortable padding */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #ef9a9a; /* Lighter shade on hover */
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(1px); /* Slight push when clicked */
  }
`;

const RefreshButton = styled.button`
  background-color: #42a5f5; /* Subtle blue */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 30px;
  cursor: pointer;
  margin-left: 40px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #64b5f6; /* Lighter blue on hover */
     transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(1px); /* Slight push on click */
  }

  &:focus {
    outline: none;
  }

  /* Animation for the icon */
  svg {
    transition: transform 0.5s ease; /* Smooth transition */
  }

  &.rotating svg {
    transform: rotate(360deg); /* Rotate the icon 360 degrees */
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  background-color: #f1c40f; /* Lighter shade of #f39c12 */
  padding: 8px 15px;
  font-size: 14px;
  color: #fff;
  justify-content: center;
  text-align: center;
  border-radius: 0 0 10px 10px; /* Rounded bottom corners */
  width: 100%; /* Span full width of the card */
  box-sizing: border-box;
  margin-top: auto; /* Push the badge to the bottom */
`;

const ApprovedContainer = styled.div`
  width: 40%; /* Center alignment */
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-top: -53px;
  margin-bottom: 10px;
  padding: 4px;
  background: #d4edda; /* Light green background */
  border-radius: 8px;
  color: #155724; /* Dark green text color */
  font-size: 12px;
  font-weight: bold;
  gap: 4px;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    width: 48%;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    width: 45%;
  }
`;


const CompanyLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 10px;
  margin-top: -15px;

  img {
    max-width: 100%;
    max-height: 150px;
    border-radius: 8px;
    object-fit: contain;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
`;

const ReversePendingApprovalContainer = styled.div`
  width: 40%; /* Center alignment */
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-top: -53px;
  margin-bottom: 10px;
  padding: 4px;
  background: #f8d7da; /* Light red background */
  border-radius: 8px;
  color: #721c24; /* Dark red text color */
  font-size: 12px;
  font-weight: bold;
  gap: 4px;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    width: 48%;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    width: 45%;
  }
`;

const DisabledContainer = styled.div`
  width: 40%; /* Center alignment */
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-top: -53px;
  margin-bottom: 10px;
  padding: 4px;
  background: #fff3cd; /* Light yellow background */
  border-radius: 8px;
  color: #856404; /* Dark yellow text color */
  font-size: 12px;
  font-weight: bold;
  gap: 4px;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    width: 48%;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    width: 45%;
  }
`;



const NoData = styled.p`
  text-align: center;
  font-size: 16px;
  color: #888;
  padding: 15px;
  border: 1px dashed #ccc;
  background-color: #f9f9f9;
  margin-top: 20px;
  font-style: italic; /* Make it more distinct */
  letter-spacing: 0.5px;
  width:500px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increased gap for better spacing between items */
  padding: 0 20px; /* Added horizontal padding for spacing from edges */
  width: 100%; /* Ensure it takes up the full width of the card */
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

// Logos and Icons styling
const UserLogo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0; /* Reduced margin */
  padding: 0; /* Remove any unnecessary padding */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Sidebar = styled.div`
  width: ${(props) => (props.isOpen ? "250px" : "0")};
  height: 100vh;
  background: #2b2b2b;
  color: white;
  transition: width 0.3s ease-in-out;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media (min-width: 1024px) {
    width: 250px;
    position: static;
    height: auto;
    flex-direction: column;
    transition: none;
  }
`;

const SidebarHeader = styled.div`
  background: linear-gradient(135deg, #1f1f1f, #333); /* Gradient for depth */
  color: #FFD700; /* Gold color for a bold and modern feel */
  padding: 10px;
  text-align: center;
  font-size: 24px; /* Increased font size for better visibility */
  font-weight: 600; /* Slightly lighter weight for a cleaner look */
  border-bottom: 2px solid #444;
  text-transform: uppercase; /* Adds a more refined touch */
  letter-spacing: 1px; /* Slight letter spacing for more elegance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: all 0.3s ease;

  /* Hover effect */
  &:hover {
    background: linear-gradient(135deg, #333, #1f1f1f); /* Reverse gradient on hover */
    transform: scale(1.02); /* Slight zoom effect */
  }
`;

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-grow: 1;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: green;
  font-size: 30px;
  z-index: 1001;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const MenuItem = styled.div`
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => (props.active ? "#4CAF50" : "white")};
  background: ${(props) => (props.active ? "#222" : "none")};
  transition: all 0.3s ease;
  &:hover {
    background: #555;
    color: #FFD700; // Golden Yellow on hover
  }
`;

const Content = styled.div`
  flex-grow: 1;
  margin-left: ${(props) => (props.isSidebarOpen ? "250px" : "0")};
  transition: margin-left 0.3s ease-in-out;
  padding: 20px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #d9534f;
  }
`;

const FormField = styled.div`
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;

    color: #333;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
    }
  }
`;

const FormActions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

const PopupButton = styled.button`
  background: ${(props) => (props.cancel ? "#ccc" : "#007bff")};
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  &:hover {
    background: ${(props) => (props.cancel ? "#999" : "#0056b3")};
  }
`;

const PopupContent = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

