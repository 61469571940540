import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FiRefreshCcw } from "react-icons/fi"; // Import refresh icon from react-icons

export default function Enquiry() {
    const [tableData, setTableData] = useState([]);

    // Fetch data from the API
    const fetchData = () => {
        fetch("https://aet-blockchain.centralindia.cloudapp.azure.com:8004/contactUs")
            .then((response) => response.json())
            .then((data) => {
                console.log("Fetched Data: ", data); // Debugging: Log the data to inspect it
                // Sort the data by enquiryId in descending order to get the latest on top
                const sortedData = data.sort((a, b) => {
                    // Debugging: Log the enquiryId values
                    console.log("Sorting by inquiryId", a.inquiryId, b.inquiryId);
                    // Ensure we are sorting in descending order
                    return (b.inquiryId || 0) - (a.inquiryId || 0); // Default to 0 if inquiryId is missing
                });
                setTableData(sortedData); // Set sorted data
            })
            .catch((error) => console.error("Error fetching data:", error));
    };

    // Initial data fetch when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Wrapper>
            <TableWrapper>
                <HeaderWrapper>
                    <h2>Submitted Queries</h2>
                    <RefreshButton onClick={fetchData}>
                        <FiRefreshCcw /> Refresh
                    </RefreshButton>
                </HeaderWrapper>
                <ScrollWrapper>
                    <Table>
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Query</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.length > 0 ? (
                                tableData.map((entry, index) => (
                                    <tr key={entry._id}>
                                        <td>{index + 1}</td> {/* Serial number */}
                                        <td>{entry.name}</td>
                                        <td>{entry.email}</td>
                                        <td>{entry.query}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </ScrollWrapper>
            </TableWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    font-family: "Roboto", sans-serif;
    background-color: #f3f4f6;
    color: #333;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
`;

const TableWrapper = styled.div`
    margin-top: 20px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    h2 {
        color: #4a5568;
        font-weight: bold;
        margin-right: 20px;
    }
`;

const RefreshButton = styled.button`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: #4c51bf;
    color: #ffffff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    svg {
        margin-right: 8px; /* Space between icon and text */
        font-size: 18px;
    }

    &:hover {
        background-color: #434190;
    }
`;

const ScrollWrapper = styled.div`
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;

    th {
        background-color: #4c51bf;
        color: #ffffff;
        padding: 15px;
        text-align: left;
        font-weight: bold;
        border: 1px solid #ddd;
    }

    td {
        border: 1px solid #ddd;
        padding: 12px;
        text-align: left;
        color: #4a5568;
    }

    tbody tr:nth-child(even) {
        background-color: #edf2f7;
    }

    tbody tr:hover {
        background-color: #ebf4ff;
        transition: background-color 0.3s ease;
    }

    @media (max-width: 768px) {
        th,
        td {
            font-size: 14px;
            padding: 10px;
        }
    }
`;
