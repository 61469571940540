import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
// Screens
import Admin from "./screens/Marketplace/Admin";
import Seller from "./screens/Marketplace/Seller";
import Buyer from "./screens/Marketplace/Buyer";
import Login from "./screens/Marketplace/Login";
import Register from "./screens/Marketplace/Register";
import MasterLanding from "./screens/Master/MasterLanding";
import BGVLanding from "./screens/BGV/BGVLanding";
import RCLanding from "./screens/RC/RCLanding";
import ELFLanding from "./screens/ELF/ELFLanding";
import MarketplaceLanding from "./screens/Marketplace/MarketplaceLanding";
import Inquiry from "./screens/Inquiry/Inquiry";

export default function App() {
  return (
    <Router>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      {/* Header Navigation */}
      <header style={{ padding: "20px", textAlign: "center" }}>
        <Link to="/" style={navButtonStyles}>
          Home
        </Link>
        <Link to="openmp/admin" style={navButtonStyles}>
          Admin
        </Link>
        <Link to="openmp/seller" style={navButtonStyles}>
          Seller
        </Link>
        <Link to="openmp/buyer" style={navButtonStyles}>
          Buyer
        </Link>
        <Link to="openmp/login" style={navButtonStyles}>
          Login
        </Link>
        <Link to="openmp/register" style={navButtonStyles}>
          Register
        </Link>
      </header>

      {/* Render the current page based on URL */}
      <main>
        <Routes>
          <Route path="openmp/admin" element={<Admin />} />
          <Route path="openmp/seller" element={<Seller />} />
          <Route path="openmp/buyer" element={<Buyer />} />
          <Route path="openmp/login" element={<Login />} />
          <Route path="openmp/register" element={<Register />} />
          <Route path="/" element={<MasterLanding />} />
          <Route path="/bgvlanding" element={<BGVLanding />} />
          <Route path="/rclanding" element={<RCLanding />} />
          <Route path="/elflanding" element={<ELFLanding />} />
          <Route path="/marketplacelanding" element={<MarketplaceLanding />} />
          <Route path="/aet-inquires" element={<Inquiry />} />
        </Routes>
      </main>
    </Router>
  );
}

// Simple button styles
const navButtonStyles = {
  padding: "10px 20px",
  margin: "0 10px",
  fontSize: "16px",
  cursor: "pointer",
};
