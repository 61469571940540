import React from "react";
import styled from "styled-components";
import { Link } from 'react-scroll';
// Components
import FullButton from "../../Buttons/FullButton";
// Assets
import HeaderImage from "/blockchain/vmdata/openmarket/demo/ui/src/assets/RC/img/projects/header.webp";
import QuotesIcon from "/blockchain/vmdata/openmarket/demo/ui/src/assets/BGV/svg/Quotes.jsx";
import Dots from "/blockchain/vmdata/openmarket/demo/ui/src/assets/BGV/svg/Dots.jsx";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60" style={{ color: "#e70812" }}>
            Blockchain-Based Track & Trace for Royal Canin
          </h1>
          <HeaderP className="font13 semiBold">
            Introducing a cutting-edge solution for Royal Canin's premium cat and dog food products. Using blockchain technology, we ensure complete traceability and transparency in the supply chain.
            From sourcing ingredients to delivery, customers can now verify the authenticity and nutritional quality of their pet's food with a simple scan.
          </HeaderP>
          <BtnWrapper>
            <Link
              style={{ padding: "10px 15px", textDecoration: "none", margin: "-10%" }}
              to="projects"
              spy={true}
              smooth={true}
              offset={-80}>
              <FullButton title="Explore More" />
            </Link>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img
            className="radius8"
            src={HeaderImage} // Replace with a relevant image of pet food or supply chain
            alt="Pet Food Traceability"
            style={{ zIndex: 9 }}
          />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>"Ensuring your pet's health and happiness with every meal, powered by trust and technology."</em>
              </p>
              <p className="font13 orangeColor textRight" style={{ marginTop: "10px" }}>
                AET-Blockchain Team
              </p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 0; /* Removed unnecessary padding-top */
  width: 100%;
  min-height: 600px; /* Reduced height to fit content better */
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 20px; /* Adjusted for mobile */
    min-height: auto; /* Allow content to flow on mobile */
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  margin-top: 0; /* Removed margin to lift it up */
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 40px 0 0 0;
    text-align: center;
  }
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  margin-top: 0; /* Removed margin to lift it up */
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 20px;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 30px 0; /* Reduced bottom padding */
  line-height: 1.5rem;
  margin-top: 10px;
  @media (max-width: 960px) {
    padding: 15px 0 20px 0; /* Reduced padding for smaller screens */
    text-align: center;
    max-width: 100%;
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  margin-left: 0;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const GreyDiv = styled.div`
  width: 30%;
  height: 400px; /* Reduced height */
  position: absolute;
  top: -60px; /* Adjusted position */
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  max-width: 400px; /* Ensures it doesn't grow too large */
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 8px;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
    max-width: 300px;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;

const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;

const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

