import React from "react";
// Sections
import SimpleNavbar from "../../components/Nav/Marketplace/TopSimpleNavbar"; import Footer from "../../components/Sections/Marketplace/Footer"
import Login from "../../components/Sections/Marketplace/Login";

export default function UserLogin() {
  return (
    <>
      <SimpleNavbar />
      <Login />
      <Footer />
    </>
  );
}
