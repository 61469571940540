import React from "react";
// Sections
import SimpleNavbar from "../../components/Nav/Marketplace/TopSimpleNavbar"; import Footer from "../../components/Sections/Marketplace/Footer"
import BuyerDashboard from "../../components/Sections/Marketplace/BuyerDashboard";

export default function Buyer() {
  return (
    <>
      <SimpleNavbar />
      <BuyerDashboard />
      <Footer />
    </>
  );
}
