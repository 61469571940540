import React from "react";
import styled from "styled-components";
import { Link } from 'react-scroll';
// Components
import ServiceBox from "../../Elements/ServiceBox";
import FullButton from "../../Buttons/FullButton";
// Assets
import AddImage1 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/ELF/img/projects/elf-service1.jpg";
import AddImage2 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/ELF/img/projects/elf-service-2.jpg";
import AddImage3 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Master/img/projects/bc-service-5.jpg";
import AddImage4 from "/blockchain/vmdata/openmarket/demo/ui/src/assets/ELF/img/projects/service-2.jpeg";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "1px 0" }}></div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Track and Trace Services</h1>
            <p className="font13">
              ELF Beauty Products is committed to ensuring the best quality in skincare and beauty products through premium formulations.
              Using blockchain technology, we bring transparency and trust to the supply chain, empowering you to verify every aspect of ELF Beauty Products' journey.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Ingredient Traceability"
                subtitle="Track the source of every ingredient in your beauty products to ensure premium quality and safety at every step."
              />
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Supply Chain Transparency"
                subtitle="Gain insights into the manufacturing and distribution process, ensuring the authenticity of ELF Beauty Products."
              />
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Real-Time Updates"
                subtitle="Access real-time data on product journey from production to your doorstep with blockchain-backed accuracy."
              />
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="Customer Trust"
                subtitle="Enhance customer confidence by offering a reliable and secure way to verify the quality and origins of beauty products."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">A few words about ELF Beauty Products</h4>
                <h2 className="font40 extraBold">Pioneering Beauty with Technology</h2>
                <p className="font12">
                  At ELF Beauty Products, we blend decades of expertise in beauty and skincare with blockchain innovation. By integrating technology,
                  we ensure that every product is not only effective but also transparent, providing peace of mind for beauty enthusiasts.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                  <div style={{ width: "190px" }}>
                    <BtnWrapper>
                      <Link
                        style={{ padding: "10px 15px", textDecoration: "none" }}
                        to="projects"
                        spy={true}
                        smooth={true}
                        offset={-80}>
                        <FullButton title="Learn More" />
                      </Link>
                    </BtnWrapper>
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <BtnWrapper>
                      <Link
                        activeClass="active"
                        className="menu-item"
                        style={{ padding: "10px 15px", textDecoration: "none" }}
                        to="contacts"  // Scroll to the "projects" section
                        spy={true}
                        smooth={true}
                        offset={-80}>
                        <FullButton title="Contact us" border />
                      </Link>
                    </BtnWrapper>
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="Beauty Product Production" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="Supply Chain Process" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="Ingredient Transparency" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="Blockchain Dashboard" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const Wrapper = styled.section`
  width: 100%;
`;

const ServiceBoxRow = styled.div`
  display: flex;
  justify-content: space-between; /* Ensure proper space between items */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 30px; /* Ensure even spacing between items */
  @media (max-width: 860px) {
    justify-content: center; /* Center the content on smaller screens */
  }
`;

const ServiceBoxWrapper = styled.div`
  width: 23%; /* Adjust width for a more balanced layout */
  padding: 40px 0;
  display: flex;
  justify-content: center; /* Center icons horizontally */
  align-items: center; /* Center icons vertically */
  text-align: center; /* Center the text */
  @media (max-width: 860px) {
    width: 100%; /* Full width on smaller screens */
    padding: 40px 0;
  }
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  display: flex;
  justify-content: space-between; /* Align both sides of the advertising section */
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;

const AddLeft = styled.div`
  width: 50%;
  text-align: left;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;

const AddRightInner = styled.div`
  width: 100%;
`;

const AddImgWrapp1 = styled.div`
  width: 48%;
  height: 0;
  padding-top: 48%;  /* Creates a square shape by using padding */
  margin: 0 6% 10px 6%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the space and maintains aspect ratio */
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddImgWrapp2 = styled.div`
  width: 30%;
  height: 0;
  padding-top: 30%;  /* Creates a square shape by using padding */
  margin: 0 5% 10px 5%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the space and maintains aspect ratio */
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddImgWrapp3 = styled.div`
  width: 20%;
  height: 0;
  padding-top: 20%;  /* Creates a square shape by using padding */
  margin-left: 40%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the space and maintains aspect ratio */
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddImgWrapp4 = styled.div`
  width: 30%;
  height: 0;
  padding-top: 30%;  /* Creates a square shape by using padding */
  margin: 0 5% auto;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the space and maintains aspect ratio */
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
