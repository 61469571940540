import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState(""); // Popup type (success/error)
  const [showPopup, setShowPopup] = useState(false);
  const [formHidden, setFormHidden] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    const identifier = e.target.identifier.value;
    const password = e.target.password.value;

    if (!identifier || !password) {
      setPopupMessage("Both fields are required.");
      setPopupType("error");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      return;
    }

    const requestBody = { identifier, password };

    try {
      const response = await fetch(
        "https://aet-blockchain.centralindia.cloudapp.azure.com:8004/auth/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      console.log("Response Data:", data);

      if (response.ok) {
        setPopupMessage(data.message);
        setPopupType("success");
        setShowPopup(true);

        // Immediately hide the form upon success
        setFormHidden(true);

        if (data.token && data.user) {
          sessionStorage.setItem("authToken", data.token);
          sessionStorage.setItem("userData", JSON.stringify(data.user));
          console.log("User Data Stored:", data.user);
        } else {
          console.error("Missing token or user data in response");
        }

        // Redirect immediately without waiting for timeout
        if (data.user?.role === "seller") {
          window.location.href = "/openmp/seller";
        } else if (data.user?.role === "admin") {
          window.location.href = "/openmp/admin";
        }
      } else {
        setPopupMessage(data.message || "Invalid credentials.");
        setPopupType("error");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setPopupMessage("An error occurred. Please try again.");
      setPopupType("error");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    }
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper showPopup={showPopup}>
        <Container showPopup={showPopup}>
          <Header>
            <h1>Login</h1>
          </Header>
          {!formHidden && (
            <Form onSubmit={handleLogin}>
              <InputWrapper>
                <Label>Email / Mobile</Label>
                <Input
                  name="identifier"
                  type="text"
                  placeholder="Enter your email or mobile no."
                  required
                />
              </InputWrapper>

              <InputWrapper>
                <Label>Password</Label>
                <PasswordWrapper>
                  <Input
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter your password"
                    required
                  />
                  <TogglePasswordIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {!passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </TogglePasswordIcon>
                </PasswordWrapper>
              </InputWrapper>

              <ButtonRow>
                <LoginButton type="submit">Login</LoginButton>
                <CancelButton
                  type="button"
                  onClick={() => {
                    window.open("/openmp/login", "_top", "noopener,noreferrer");
                  }}
                >
                  Cancel
                </CancelButton>
              </ButtonRow>
              <RegisterLink>
                New user?{" "}
                <a href="/openmp/register" target="_top" rel="noopener noreferrer">
                  Register here
                </a>
              </RegisterLink>
            </Form>
          )}

        </Container>
        {showPopup && <Popup popupType={popupType}>{popupMessage}</Popup>}
      </Wrapper>
    </>
  );
}

// Styled Components
const GlobalStyle = createGlobalStyle`
  input:-webkit-autofill {
    -webkit-text-fill-color: #333333 !important;  /* Set text color to dark */
    background-color: #f0f0f0 !important;        /* Lighter background for autofill */
    color: #333333 !important;                   /* Ensure the text color is dark */
  }

  input:-webkit-autofill::placeholder {
    color: #888888 !important;   /* Set placeholder text color */
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const TogglePasswordIcon = styled.div`
  position: absolute;
  right: 12px;
  font-size: 1rem;
  color: #888888;
  cursor: pointer;

  &:hover {
    color: #007bff;
  }
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background: url('https://t3.ftcdn.net/jpg/05/66/34/10/360_F_566341036_f2mCzWyHi9I4aMOSSiy1XUUhvEqdUKJ1.jpg') no-repeat center center fixed;
  background-size: cover;

  /* Overlay to cover the entire screen when popup is active */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ showPopup }) => (showPopup ? "rgba(0, 0, 0, 0.7)" : "none")}; /* Dark overlay */
    z-index: ${({ showPopup }) => (showPopup ? 2 : -1)}; /* Ensure overlay is above the content */
    transition: background 0.3s ease;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 3; /* Keep the form above the background */
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
  padding: 30px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 550px;
  margin-top: -4%;
  display: ${({ showPopup }) => (showPopup ? "none" : "block")}; /* Hide form when popup is visible */
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) =>
    props.popupType === "error" ? "#e74c3c" : "#28a745"}; /* Red for error, Green for success */
  color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1000; /* Bring the popup to the top layer */
  text-align: center;
  animation: fadeInOut 4s;

  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
    10%, 90% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 15px;

  h1 {
    font-size: 2rem;
    color: #333333;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 0.9rem;
  margin-bottom: 8px;
  color: #555555;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #cccccc;
  border-radius: 6px;
  transition: border-color 0.3s;
  background-color: #f9f9f9;  /* Light background color for inputs */
  color: #333;  /* Dark text color for readability */
  
  &::placeholder {
    color: #888888;
  }

  &:focus {
    border-color: #6a82fb;  /* Highlight the border on focus */
    outline: none;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;
`;

const Button = styled.button`
  width: 48%;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const LoginButton = styled(Button)`
  background-color: #eb189c;
  color: white;

  &:hover {
    background-color: #d91a85;
  }
`;

const CancelButton = styled(Button)`
  background-color: grey;
  color: white;

  &:hover {
    background-color: #c0392b;
  }
`;

const RegisterLink = styled.div`
  text-align: center;
  margin-top: 15px;

  a {
    color: #F2B300;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;
