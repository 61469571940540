import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Backdrop from "../../Elements/Backdrop";
import FujitsuLogo from "/blockchain/vmdata/openmarket/demo/ui/src/assets/BGV/img/projects/fj-logo.png";

export default function TopSellerNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper style={y > 100 ? { height: "50px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" } : { height: "60px" }}>
        <NavInner>
          <Logo href="https://aet-blockchain.centralindia.cloudapp.azure.com/marketplacelanding/">
            <img src={FujitsuLogo} alt="Fujitsu Logo" />
          </Logo>
          <NavLinks>
            <li>
              <NavButton href="https://aet-blockchain.centralindia.cloudapp.azure.com/marketplacelanding/">
                Explore Marketplace
              </NavButton>
            </li>
          </NavLinks>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #ffffff;
  transition: height 0.3s ease, box-shadow 0.3s ease;
`;

const NavInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 760px) {
    padding: 0 15px;
  }
`;

const Logo = styled.a`
  margin-right: auto; /* Ensures logo stays aligned to the left */
  margin-left:-4%;
  margin-top:1%;
  img {
    width: 100px; /* Reduced logo size to make navbar more compact */
    height: auto;
    transition: all 0.3s ease;
  }
     @media (max-width: 760px) {
        margin-left:3%;
        margin-top:3%;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  @media (max-width: 760px) {
    display: none;
  }
  li {
    margin-left: 20px;
  }
`;

const NavButton = styled.a`
  padding: 10px 20px;
  border-radius: 30px;
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #2575fc, #6a11cb);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
`;

