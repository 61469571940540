import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from 'react-scroll';
// Components
import FullButton from "../../Buttons/FullButton";
// Assets
import ContactImage from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/footer.jpeg";

const GlobalStyle = createGlobalStyle`
  input:-webkit-autofill {
    -webkit-text-fill-color: #333333 !important;  /* Set text color to dark */
    background-color: #f0f0f0 !important;        /* Lighter background for autofill */
    color: #333333 !important;                   /* Ensure the text color is dark */
  }

  input:-webkit-autofill::placeholder {
    color: #888888 !important;   /* Set placeholder text color */
  }

  textarea:-webkit-autofill {
    -webkit-text-fill-color: #333333 !important;
    background-color: #f0f0f0 !important;
    color: #333333 !important;
  }

  textarea:-webkit-autofill::placeholder {
    color: #888888 !important;
  }
`;

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    query: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Query submitted by: ${formData.name}`);
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper id="contacts">
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <ImgWrapper>
                  <img className="radius8" src={ContactImage} alt="Artisan Working" />
                </ImgWrapper>
              </AddLeft>
              <AddRight>
                <h4 className="font15 semiBold">Empowering Communities</h4>
                <h2 className="font40 extraBold">The Heart of Handicrafts</h2>
                <p className="font12">
                  Our mission is to create opportunities for rural artisans by connecting their craft to the world. By supporting these talented creators, you help
                  sustain traditions, uplift families, and preserve cultural heritage.
                  <br />
                  We leverage Blockchain Technology to ensure that every artisan's work is traceable, guaranteeing transparency and authenticity in every purchase.
                  <br />
                  Through AI , we are enhancing the shopping experience by providing personalized product recommendations based on your preferences.
                </p>
                <BtnWrapper>
                  <Link
                    style={{
                      padding: "10px 15px",
                      textDecoration: "none", // Remove underline
                      marginTop: "10px",
                      border: "none", // Ensure no border
                    }}
                    to="projects"
                    spy={true}
                    smooth={true}
                    offset={-80}>
                    <FullButton title="Explore More" />
                  </Link>
                </BtnWrapper>
              </AddRight>
              <ContactForm onSubmit={handleSubmit} id="contactus">
                <h4 className="font30 bold">Contact Us</h4>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <textarea
                  name="query"
                  placeholder="Your Query"
                  rows="4"
                  value={formData.query}
                  onChange={handleChange}
                  required
                ></textarea>
                <SubmitButton type="submit">Submit</SubmitButton>
              </ContactForm>
            </Advertising>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const BtnWrapper = styled.div`
  margin-top: 12%;
  max-width: 190px;

  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 50px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const AddLeft = styled.div`
  width: 33%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const AddRight = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 390px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContactForm = styled.form`
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  input,
  textarea {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  textarea {
    resize: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #eb189c;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #d81b60;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
