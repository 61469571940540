import React, { useRef, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import businessLogoUrl from '/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/building-logo.jpeg';
import {
  FaMapPin,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaArchive,
  FaEdit,
  FaBan,
  FaSignOutAlt,
  FaUserTie,
  FaBriefcase,
  FaFileInvoiceDollar,
  FaLocationArrow,
  FaTimes,
  FaBars,
  FaUserAlt,
  FaStore,
  FaMapMarkedAlt,
  FaUserCircle,
  FaExclamationCircle,
  FaClock,
  FaSearch,
  FaSignInAlt,
  FaCheckCircle,
} from "react-icons/fa";
import { FaGear, FaHandsClapping } from "react-icons/fa6";

export default function AdminDashboard() {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [sellerProfiles, setSellerProfiles] = useState([]);

  const [selectedBusinessProfile, setSelectedBusinessProfile] = useState(null);
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showEnablePopup, setShowEnablePopup] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageBoxType, setMessageBoxType] = useState("success"); // "success" or "error"
  const [messageText, setMessageText] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedMenu, setSelectedMenu] = useState("Business Information");
  const [showPersonalEditPopup, setShowPersonalEditPopup] = useState(false); // Separate popup for personal profile
  const [showPopup, setShowPopup] = useState(false);

  const [personalInfo, setPersonalInfo] = useState({
    fullName: "",
    email: "",
    phone: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [businessFormData, setBusinessFormData] = useState({
    businessName: "",
    businessType: "",
    taxId: "",
    storeName: "",
    address: {
      state: "",
      city: "",
      zipCode: "",
      street: ""
    },
  });

  const formatDate = (date) => {
    const options = {
      weekday: "short", year: "numeric", month: "short", day: "numeric",
      hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true,
    };
    return new Date(date).toLocaleString(undefined, options);
  };

  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref, callback]);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handlePersonalFormChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleLogout = () => {
    sessionStorage.clear(); // Clear all session storage
    window.location.href = "/openmp/login"; // Redirect to login page
  };

  const handlePersonalSaveChanges = async () => {
    const token = sessionStorage.getItem("authToken"); // Get bearer token from sessionStorage
    const updatedInfo = {
      name: personalInfo.fullName,
      email: personalInfo.email,
      mobileNumber: personalInfo.phone,
    };

    const originalInfo = JSON.parse(sessionStorage.getItem("userData")) || {};

    if (
      updatedInfo.name === originalInfo.name &&
      updatedInfo.email === originalInfo.email &&
      updatedInfo.mobileNumber === originalInfo.mobileNumber
    ) {
      setMessageBoxType("info");
      setMessageText("No changes made!!");
      setShowMessageBox(true); // Show info message
      setTimeout(() => {
        setShowMessageBox(false); // Hide the message
        window.location.reload(); // Reload the page to refresh the info card
      }, 2000); // Hide after 3 seconds
      return; // Exit without making the API call
    }

    try {
      const response = await fetch("https://aet-blockchain.centralindia.cloudapp.azure.com:8004/user", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add the bearer token
        },
        body: JSON.stringify(updatedInfo),
      });

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem("userData", JSON.stringify(data));
        setMessageBoxType("success");
        setMessageText("Saving data. Please wait!!");
        setShowMessageBox(true); // Show success message
        setTimeout(() => {
          setShowMessageBox(false); // Hide the message
          window.location.reload(); // Reload the page to refresh the info card
        }, 2000); // Hide after 3 seconds
      } else {
        setMessageBoxType("error");
        setMessageText("Session expired. Please log in again.");
        setShowMessageBox(true); // Show error message
        setTimeout(() => {
          setShowMessageBox(false); // Hide the message
          window.location.reload(); // Reload the page to refresh the info card
        }, 2000); // Hide after 3 seconds
      }

    } catch (error) {
      console.error('Error updating personal information:', error);
      setMessageBoxType("error");
      setMessageText("An error occurred while updating the information.");
      setShowMessageBox(true); // Show error message
      setTimeout(() => {
        setShowMessageBox(false); // Hide the message
        window.location.reload(); // Reload the page to refresh the info card
      }, 2000); // Hide after 3 seconds
    }
  };

  const handleBusinessDisableChanges = async (selectedBusinessProfile) => {
    const token = sessionStorage.getItem("authToken");
    const businessId = selectedBusinessProfile?.businessId;

    if (!businessId) {
      console.error("Business ID not found.");
      return;
    }
    if (!token) {
      console.error("Auth Token missing.");
      return;
    }

    // Toggle the status between "active" and "disabled"
    const newStatus = selectedBusinessProfile.status === "active" ? "disabled" : "active";
    const body = {
      status: newStatus,
    };

    try {
      const response = await fetch(
        `https://aet-blockchain.centralindia.cloudapp.azure.com:8004/sellers/business/${businessId}/status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        // Show success message
        setMessageBoxType("success");
        setMessageText(`Business profile status being changed to ${newStatus === "active" ? "enabled" : "disabled"}. Please wait!`);
        setShowMessageBox(true);

        // Reload the page after 1 second
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.error("Failed to update business status:", response.statusText);
        setMessageBoxType("error");
        setMessageText(`Failed to ${newStatus === "active" ? "enable" : "disable"} business profile. Please try again.`);
        setShowMessageBox(true);

        setTimeout(() => {
          setShowMessageBox(false);
        }, 2000);
      }
    } catch (error) {
      console.error(`Error updating business status:`, error);
      setMessageBoxType("error");
      setMessageText("An error occurred while updating the business profile status.");
      setShowMessageBox(true);

      setTimeout(() => {
        setShowMessageBox(false);
      }, 2000);
    }
  };

  const fetchBusinessProfile = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("authToken");

    if (!token) {
      console.error("Auth Token missing.");
      setLoading(false);
      return;
    }

    try {
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      const userId = userData?.id || userData?._id;

      if (!userId) {
        console.error("User ID missing.");
        setLoading(false);
        return;
      }

      const response = await fetch(
        `https://aet-blockchain.centralindia.cloudapp.azure.com:8004/sellers/${userId}/business`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch business profiles:", response.statusText);
        setBusinessProfile([]);  // Set empty array if fetch fails
        setLoading(false);
        return;
      }

      const data = await response.json();

      // Store the fetched data in sessionStorage
      sessionStorage.setItem('businessProfiles', JSON.stringify(data || []));

      // Set the fetched data to state
      setBusinessProfile(data || []);
    } catch (error) {
      console.error("Error fetching business profiles:", error);
      alert("Error fetching business profiles. Please check your connection.");
      setBusinessProfile([]);  // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  const changeBusinessStatus = async (businessId, currentStatus) => {
    const token = sessionStorage.getItem("authToken");

    if (!businessId) {
      console.error("Business ID not found.");
      return;
    }

    if (!token) {
      console.error("Auth Token missing.");
      return;
    }

    try {
      setLoading(true); // Set loading to true

      // Toggle between active and pending
      const newStatus = currentStatus.toLowerCase() === "active" ? "pending" : "active";

      const body = {
        status: newStatus,
      };

      if (newStatus === "active") {
        body.statusRemark = "This business is now active.";
      } else {
        body.statusRemark = "This business is now pending.";
      }

      const response = await fetch(
        `https://aet-blockchain.centralindia.cloudapp.azure.com:8004/sellers/business/${businessId}/status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        console.error("Failed to update business status:", response.statusText);
        alert("Failed to update status. Please try again.");
        setLoading(false);
        return;
      }

      // Reload the page after successful status update
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error updating business status:", error);
      alert("Error updating business status. Please check your connection.");
      setLoading(false);
    }
  };

  const allSellerProfiles = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("authToken");

    if (!token) {
      console.error("Auth Token missing.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "https://aet-blockchain.centralindia.cloudapp.azure.com:8004/sellers/business",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch seller profiles:", response.statusText);
        setSellerProfiles([]); // Set empty array if fetch fails
        setLoading(false);
        return;
      }

      const data = await response.json();

      // Process the response to extract and organize data
      const sellerProfiles = data.map((seller) => {
        const { userId, name, businesses } = seller;
        return {
          userId,
          name,
          businesses: businesses.map((business) => ({
            businessId: business._id,
            businessName: business.businessName,
            businessType: business.businessType,
            taxId: business.taxId,
            storeName: business.storeName,
            categories: business.categories || [],
            isVerified: business.isVerified,
            status: business.status,
            statusRemark: business.statusRemark,
            rating: business.rating || 0,
            reviewsCount: business.reviewsCount || 0,
            address: business.address || {},
            createdAt: business.createdAt,
            updatedAt: business.updatedAt,
          })),
        };
      });

      // Store the fetched data in sessionStorage
      sessionStorage.setItem("sellerProfiles", JSON.stringify(sellerProfiles));

      // Set the fetched data to state
      setSellerProfiles(sellerProfiles);
    } catch (error) {
      console.error("Error fetching seller profiles:", error);
      alert("Error fetching seller profiles. Please check your connection.");
      setSellerProfiles([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleDisablePopupOpen = (businessProfile) => {
    setSelectedBusinessProfile(businessProfile);
    setShowDisablePopup(true);
  };

  const handleEnablePopupOpen = (businessProfile) => {
    setSelectedBusinessProfile(businessProfile);
    setShowEnablePopup(true);
  };

  const handleDisableConfirm = () => {
    if (selectedBusinessProfile) {
      handleBusinessDisableChanges(selectedBusinessProfile);
    }
    setShowDisablePopup(false);
    setSelectedBusinessProfile(null);
  };

  const handleEnableConfirm = () => {
    if (selectedBusinessProfile) {
      handleBusinessDisableChanges(selectedBusinessProfile);
    }
    setShowEnablePopup(false);
    setSelectedBusinessProfile(null);
  };

  const handleDisableCancel = () => {
    setShowDisablePopup(false);
    setSelectedBusinessProfile(null);
  };

  const handleEnableCancel = () => {
    setShowEnablePopup(false);
    setSelectedBusinessProfile(null);
  };

  const togglePopup = (profile, business) => {
    setSelectedProfile(profile);
    setSelectedBusiness(business);
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    const businessProfile = JSON.parse(sessionStorage.getItem("businessProfiles"));
    if (businessProfile)
      setBusinessFormData({
        businessName: businessProfile.businessName || "",
        businessType: businessProfile.businessType || "",
        taxId: businessProfile.taxId || "",
        storeName: businessProfile.storeName || "",
        address: {
          state: businessProfile.address?.state || "",
          city: businessProfile.address?.city || "",
          zipCode: businessProfile.address?.zipCode || "",
          street: businessProfile.address?.street || "",
        },
      });
  }, [businessProfile]);

  useEffect(() => {
    // Fetch user data from sessionStorage
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if (userData) {
      setPersonalInfo({
        fullName: userData.name || "",
        email: userData.email || "",
        phone: userData.mobileNumber || "",
      });
    }
  }, []);

  useEffect(() => {
    fetchBusinessProfile();
  }, []);

  useEffect(() => {
    allSellerProfiles();
  }, []);

  useOutsideClick(sidebarRef, () => setIsSidebarOpen(false));

  const renderContent = () => {

    if (selectedMenu === "Personal Information") {
      return (
        <CardContainer>
          {/* Personal Profile Section */}
          {sessionStorage.getItem("businessProfiles") ? (
            <Card>
              <CardHeader>
                <CardTitle>
                  <FaUserAlt />
                  Personal Profile
                </CardTitle>
                {personalInfo && personalInfo.fullName && personalInfo.email && personalInfo.phone && (
                  <EditButton onClick={() => setShowPersonalEditPopup(true)}>
                    <FaEdit />
                  </EditButton>
                )}
              </CardHeader>

              <CardContent style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '20px' }}>
                {personalInfo && personalInfo.fullName && personalInfo.email && personalInfo.phone ? (
                  <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    {/* Image on the left */}
                    <UserLogo
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        marginRight: '20px',
                        width: '100px',
                        height: '100px',
                        overflow: 'hidden',
                        borderRadius: '50%',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <img
                        src={businessLogoUrl}
                        alt="User Logo"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          transition: 'transform 0.3s ease',
                        }}
                      />
                    </UserLogo>

                    {/* Content on the right */}
                    <InfoContainer style={{ flex: 1 }}>
                      <CardItem style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '10px' }}>
                        <CardIcon>
                          <FaUser />
                        </CardIcon>
                        <div>{personalInfo.fullName}</div>
                      </CardItem>
                      <CardItem style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '10px' }}>
                        <CardIcon>
                          <FaEnvelope />
                        </CardIcon>
                        <div>{personalInfo.email}</div>
                      </CardItem>
                      <CardItem style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '10px' }}>
                        <CardIcon>
                          <FaPhone />
                        </CardIcon>
                        <div>{personalInfo.phone}</div>
                      </CardItem>
                    </InfoContainer>
                  </div>
                ) : (
                  <NoData>
                    Session expired. Please <a href="/openmp/login" style={{
                      color: "#007bff", // Blue link color
                      textDecoration: "none", // Remove underline
                      fontWeight: "bold", // Make the text bold
                      padding: "5px 10px", // Add some padding for better spacing
                      border: "1px solid #007bff", // Add a border matching the link color
                      borderRadius: "5px", // Rounded corners
                      display: "inline-block", // Ensure proper padding effect
                      marginTop: "10px", // Add some space from the text above
                      transition: "all 0.3s ease", // Smooth hover effect
                    }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#007bff"; // Blue background on hover
                        e.target.style.color = "#fff"; // White text on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "transparent"; // Reset background
                        e.target.style.color = "#007bff"; // Reset text color
                      }}
                    >
                      log in
                    </a> again.
                  </NoData>
                )}
              </CardContent>
            </Card>
          ) : (
            <NoData>
              Session expired. Please <a href="/openmp/login" style={{
                color: "#007bff", // Blue link color
                textDecoration: "none", // Remove underline
                fontWeight: "bold", // Make the text bold
                padding: "5px 10px", // Add some padding for better spacing
                border: "1px solid #007bff", // Add a border matching the link color
                borderRadius: "5px", // Rounded corners
                display: "inline-block", // Ensure proper padding effect
                marginTop: "10px", // Add some space from the text above
                transition: "all 0.3s ease", // Smooth hover effect
              }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#007bff"; // Blue background on hover
                  e.target.style.color = "#fff"; // White text on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "transparent"; // Reset background
                  e.target.style.color = "#007bff"; // Reset text color
                }}
              >
                log in
              </a> again.
            </NoData>

          )}
        </CardContainer>
      );
    }

    if (selectedMenu === "Business Information") {
      // Filter business profiles based on the search term across multiple fields
      const filteredProfiles = sellerProfiles.filter((profile) => {
        const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();

        // If the search term is empty, include all profiles
        if (!lowerCaseSearchTerm) return true;

        // Filter businesses within each profile based on search term
        const filteredBusinesses = profile.businesses?.filter((business) => {
          const businessName = business.businessName?.trim().toLowerCase() || "";
          const businessType = business.businessType?.trim().toLowerCase() || "";
          const status = business.status?.trim().toLowerCase() || "";
          const name = profile.name?.trim().toLowerCase() || "";

          return (
            businessName.includes(lowerCaseSearchTerm) ||
            businessType.includes(lowerCaseSearchTerm) ||
            status.includes(lowerCaseSearchTerm) ||
            name.includes(lowerCaseSearchTerm)
          );
        });

        // If there are matching businesses, include the profile
        return filteredBusinesses && filteredBusinesses.length > 0;
      });

      return (
        <div>
          {sessionStorage.getItem("businessProfiles") ? (
            <div>
              {/* Search Box */}
              <div
                style={{
                  margin: "20px 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <input
                  type="text"
                  placeholder="Search Business Information.."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                  style={{
                    padding: "12px 15px",
                    fontSize: "16px",
                    width: "100%",
                    maxWidth: "400px",
                    borderRadius: "30px",
                    border: "1px solid #ddd",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease",
                    marginBottom: "10px",
                    marginTop: "-25px",
                    marginLeft: "-7%",
                  }}
                />
              </div>

              {/* Table */}
              <SellerProfilesContainer>
                {loading ? (
                  <p>Loading seller profiles...</p>
                ) : filteredProfiles.length > 0 ? (
                  <ResponsiveContainer style={{ marginTop: "-20px" }}>
                    <ResponsiveTable>
                      <thead>
                        <tr>
                          <th style={tableHeaderStyle}>Business Name</th>
                          <th style={tableHeaderStyle}>Seller Name</th>
                          <th style={tableHeaderStyle}>Business Type</th>
                          <th style={tableHeaderStyle}>Review Profile</th>
                          <th style={tableHeaderStyle}>Business Status</th>
                          <th style={tableHeaderStyle}>Profile Status</th>
                        </tr>
                      </thead>
                      <TableBody>
                        {filteredProfiles
                          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                          .map((profile) =>
                            profile.businesses
                              .filter((business) => {
                                const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
                                const businessName = business.businessName?.trim().toLowerCase() || "";
                                const businessType = business.businessType?.trim().toLowerCase() || "";
                                const status = business.status?.trim().toLowerCase() || "";
                                const name = profile.name.trim().toLowerCase() || "";
                                return (
                                  businessName.includes(lowerCaseSearchTerm) ||
                                  businessType.includes(lowerCaseSearchTerm) ||
                                  name.includes(lowerCaseSearchTerm) ||
                                  status.includes(lowerCaseSearchTerm)
                                );
                              })
                              .reverse()
                              .map((business) => (
                                <tr
                                  key={`${profile.userId}-${business.businessId}`}
                                // style={{
                                //   backgroundColor:
                                //     business.status === "archived" || business.status === "disabled"
                                //       ? "#f0f2f5" // Lighter shade of grey for disabled/archived rows
                                //       : "#fff",
                                //   opacity: business.status === "archived" || business.status === "disabled" ? 0.6 : 1,
                                //   transition: "background-color 0.3s ease",
                                // }}
                                >
                                  <td style={tableCellStyle}>{business.businessName || "N/A"}</td>
                                  <td style={tableCellStyle}>{profile.name || "N/A"}</td>
                                  <td style={tableCellStyle}>{business.businessType || "N/A"}</td>
                                  <td style={tableCellStyle}>
                                    <ActionButton
                                      // disabled={business.status === "archived" || business.status === "disabled"}
                                      onClick={() => togglePopup(profile, business)}
                                    >
                                      <FaSearch />
                                      View
                                    </ActionButton>
                                  </td>

                                  <td style={tableCellStyle}>
                                    {business.status === "archived" ? (
                                      <ArchivedText>
                                        <FaArchive style={{ fontSize: "16px" }} />
                                        Archived
                                      </ArchivedText>
                                    ) : business.status === "disabled" ? (
                                      <DisabledText>
                                        <FaBan style={{ fontSize: "16px" }} />
                                        Disabled
                                      </DisabledText>
                                    ) : (
                                      <ToggleSwitch status={business.status}>
                                        <div className="switch-container">
                                          <input
                                            type="checkbox"
                                            id={`toggle-${business.businessId}`}
                                            checked={business.status === "active"}
                                            onChange={() => changeBusinessStatus(business.businessId, business.status || "pending")}
                                            disabled={business.status === "active" || business.status === "archived"} // Disable toggle if status is active or archived
                                          />
                                          <label
                                            htmlFor={`toggle-${business.businessId}`}
                                            style={{
                                              cursor: business.status === "active" || business.status === "archived" ? "not-allowed" : "pointer",  // Disable pointer cursor on hover if status is active or archived
                                              backgroundColor: business.status === "active" ? "#28a745" : "#ffc107",  // Active status in green
                                            }}
                                          >
                                            <div className="slider"></div>
                                            <span className="label-text">
                                              {business.status === "active" ? "Active" : "Pending"}
                                            </span>
                                          </label>
                                        </div>
                                      </ToggleSwitch>
                                    )}
                                  </td>




                                  <td style={tableCellStyle}>
                                    <DisableButton
                                      onClick={() => {
                                        if (business.status === "active") {
                                          handleDisablePopupOpen(business); // Call the function to toggle the status if not pending
                                        }
                                        if (business.status === "disabled") {
                                          handleEnablePopupOpen(business); // Call the function to toggle the status if not pending
                                        }
                                      }}
                                      style={{
                                        cursor:
                                          business.status === "pending" || business.status === "archived"
                                            ? "not-allowed" // Change cursor to not-allowed for non-clickable statuses
                                            : "pointer", // Pointer cursor for clickable states
                                        backgroundColor:
                                          business.status === "archived"
                                            ? "#6c757d" // Gray for archived
                                            : business.status === "pending"
                                              ? "#e0e0e0" // Light gray for pending
                                              : business.status === "active"
                                                ? "#dc3545" // Red for active (disable)
                                                : "#28a745", // Green for enable
                                        color: "#fff", // White text for all states
                                        border: "none", // Removes the border
                                      }}
                                      disabled={business.status === "pending" || business.status === "archived"} // Disable the button for archived or pending statuses
                                    >
                                      {business.status === "pending" ? (
                                        <FaCheckCircle style={{ fontSize: "16px" }} /> // Icon for pending
                                      ) : business.status === "active" ? (
                                        <FaBan style={{ fontSize: "16px" }} /> // Icon for disable when active
                                      ) : business.status === "archived" ? (
                                        <FaArchive style={{ fontSize: "16px" }} /> // Icon for archive
                                      ) : (
                                        <FaCheckCircle style={{ fontSize: "16px" }} /> // Icon for enable
                                      )}
                                      {business.status === "archived"
                                        ? "ARCHIVED"
                                        : business.status === "active"
                                          ? "DISABLE"
                                          : "ENABLE"} {/* Button label dynamically */}
                                    </DisableButton>
                                  </td>






                                </tr>

                              ))
                          )}
                      </TableBody>
                    </ResponsiveTable>
                  </ResponsiveContainer>
                ) : (
                  <NoData>No Business details found</NoData>
                )}
              </SellerProfilesContainer>

            </div>
          ) : (
            <NoData>
              Session expired. Please <a href="/openmp/login" style={{
                color: "#007bff", // Blue link color
                textDecoration: "none", // Remove underline
                fontWeight: "bold", // Make the text bold
                padding: "5px 10px", // Add some padding for better spacing
                border: "1px solid #007bff", // Add a border matching the link color
                borderRadius: "5px", // Rounded corners
                display: "inline-block", // Ensure proper padding effect
                marginTop: "10px", // Add some space from the text above
                transition: "all 0.3s ease", // Smooth hover effect
              }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#007bff"; // Blue background on hover
                  e.target.style.color = "#fff"; // White text on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "transparent"; // Reset background
                  e.target.style.color = "#007bff"; // Reset text color
                }}
              >
                log in
              </a> again.
            </NoData>
          )}

          {/* disable Confirmation Popup */}
          {showDisablePopup && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  background: "#fff",
                  padding: "20px",
                  width: "350px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                }}
              >
                <h3 style={{ marginBottom: "10px" }}>Confirm Disable</h3>
                <p style={{ marginBottom: "20px" }}>
                  Are you sure you want to disable the business profile for{" "}
                  <span style={{ fontWeight: "bold", color: "#e74c3c" }}>
                    {selectedBusinessProfile?.businessName || "this business"}
                  </span>?
                </p>
                <button
                  onClick={handleDisableConfirm}
                  style={{
                    margin: "5px",
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "4px",
                    background: "#d9534f",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Disable
                </button>
                <button
                  onClick={handleDisableCancel}
                  style={{
                    margin: "5px",
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "4px",
                    background: "#ccc",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {showEnablePopup && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  background: "#fff",
                  padding: "20px",
                  width: "350px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                }}
              >
                <h3 style={{ marginBottom: "10px" }}>Confirm Enable</h3>
                <p style={{ marginBottom: "20px" }}>
                  Are you sure you want to enable the business profile for{" "}
                  <span style={{ fontWeight: "bold", color: "#e74c3c" }}>
                    {selectedBusinessProfile?.businessName || "this business"}
                  </span>?
                </p>
                <button
                  onClick={handleEnableConfirm}
                  style={{
                    margin: "5px",
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "4px",
                    background: "#d9534f",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Enable
                </button>
                <button
                  onClick={handleEnableCancel}
                  style={{
                    margin: "5px",
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "4px",
                    background: "#ccc",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {/* Popup for Business Details */}
          {showPopup && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  background: "#fff",
                  padding: "20px",
                  borderRadius: "16px",
                  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
                  textAlign: "left",
                  width: "80%",
                  maxWidth: "700px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#333",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    <FaUserTie style={{ fontSize: "24px", color: "#007bff" }} />
                    <span style={{ textTransform: "uppercase" }}>{selectedBusiness?.storeName}</span>
                  </div>

                  <button
                    onClick={togglePopup}
                    style={{
                      padding: "8px 15px",
                      border: "none",
                      borderRadius: "30px",
                      background: "#ff5f5f",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "500",
                      transition: "background-color 0.3s ease",
                      outline: "none",
                    }}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#e84a4a")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#ff5f5f")}
                  >
                    Close
                  </button>
                </div>

                {/* Profile Information */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "20px",
                    fontSize: "14px",
                    color: "#555",
                  }}
                >
                  {/* Seller Name */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      backgroundColor: "#f4f7f9",
                      padding: "14px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaStore style={{ fontSize: "20px", color: "#007bff" }} />
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#333",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{selectedProfile?.name || "N/A"}</strong>
                      <span style={{ fontSize: "14px", color: "#777" }}>Seller Name</span>
                    </div>
                  </div>

                  {/* Business Type */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      backgroundColor: "#f4f7f9",
                      padding: "14px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaBriefcase style={{ fontSize: "20px", color: "#007bff" }} />
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#333",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{selectedBusiness?.businessType || "N/A"}</strong>
                      <span style={{ fontSize: "14px", color: "#777" }}>Business Type</span>
                    </div>
                  </div>

                  {/* Tax ID */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      backgroundColor: "#f4f7f9",
                      padding: "14px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaFileInvoiceDollar style={{ fontSize: "20px", color: "#007bff" }} />
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#333",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{selectedBusiness?.taxId || "N/A"}</strong>
                      <span style={{ fontSize: "14px", color: "#777" }}>Tax ID</span>
                    </div>
                  </div>

                  {/* State */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      backgroundColor: "#f4f7f9",
                      padding: "14px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaMapMarkedAlt style={{ fontSize: "20px", color: "#007bff" }} />
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#333",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{selectedBusiness?.address.state || "N/A"}</strong>
                      <span style={{ fontSize: "14px", color: "#777" }}>State</span>
                    </div>
                  </div>

                  {/* City */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      backgroundColor: "#f4f7f9",
                      padding: "14px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaMapMarkedAlt style={{ fontSize: "20px", color: "#007bff" }} />
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#333",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{selectedBusiness?.address.city || "N/A"}</strong>
                      <span style={{ fontSize: "14px", color: "#777" }}>City</span>
                    </div>
                  </div>

                  {/* Street */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      backgroundColor: "#f4f7f9",
                      padding: "14px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaLocationArrow style={{ fontSize: "20px", color: "#007bff" }} />
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#333",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{selectedBusiness?.address.street || "N/A"}</strong>
                      <span style={{ fontSize: "14px", color: "#777" }}>Street</span>
                    </div>
                  </div>

                  {/* Zipcode */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      backgroundColor: "#f4f7f9",
                      padding: "14px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaMapPin style={{ fontSize: "20px", color: "#007bff" }} />
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#333",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{selectedBusiness?.address.zipCode || "N/A"}</strong>
                      <span style={{ fontSize: "14px", color: "#777" }}>Zipcode</span>
                    </div>
                  </div>

                  {/* Last Updated */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      backgroundColor: "#f4f7f9",
                      padding: "14px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaClock style={{ fontSize: "20px", color: "#007bff" }} />
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#333",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <strong>{formatDate(selectedBusiness?.updatedAt)}</strong>
                      <span style={{ fontSize: "14px", color: "#777" }}>Last Updated</span>
                    </div>
                  </div>
                </div>

                {/* Approval Status */}
                {(() => {
                  let statusStyles;
                  let statusMessage;
                  switch (selectedBusiness?.status?.toLowerCase()) {
                    case "approved":
                      statusStyles = {
                        backgroundColor: "#d4edda",
                        color: "#28a745",
                      };
                      statusMessage = "This business is approved";
                      break;
                    case "archived":
                      statusStyles = {
                        backgroundColor: "#f8d7da",
                        color: "#721c24",
                      };
                      statusMessage = "This business is archived";
                      break;
                    case "disabled":
                      statusStyles = {
                        backgroundColor: "#fff3cd",
                        color: "#856404",
                      };
                      statusMessage = "This business is disabled";
                      break;
                    case "pending":
                      statusStyles = {
                        backgroundColor: "#cce5ff",
                        color: "#004085",
                      };
                      statusMessage = "This business is pending approval";
                      break;
                    case "active":
                      statusStyles = {
                        backgroundColor: "#d4edda",
                        color: "#155724",
                      };
                      statusMessage = "This business is active";
                      break;
                    default:
                      statusStyles = {
                        backgroundColor: "#e2e3e5",
                        color: "#6c757d",
                      };
                      statusMessage = "Status not specified";
                      break;
                  }

                  return (
                    <div
                      style={{
                        ...statusStyles,
                        padding: "8px 15px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {selectedBusiness?.status?.toLowerCase() === "approved" && <FaCheckCircle />}
                      {selectedBusiness?.status?.toLowerCase() === "archived" && <FaArchive />}
                      {selectedBusiness?.status?.toLowerCase() === "disabled" && <FaBan />}
                      {selectedBusiness?.status?.toLowerCase() === "pending" && <FaExclamationCircle />}
                      {selectedBusiness?.status?.toLowerCase() === "active" && <FaCheckCircle />}
                      <span>{statusMessage}</span>
                    </div>
                  );
                })()}

              </div>
              <style jsx>{`
            @media (max-width: 760px) {
              div[style*="maxWidth: 700px"] {
                width: 90%;
                padding: 15px;
                border-radius: 10px;
              }

              div[style*="gridTemplateColumns: '1fr 1fr'"] {
                grid-template-columns: 1fr;
              }

              div[style*="fontSize: 20px"] {
                font-size: 18px;
              }

              div[style*="fontSize: 14px"] {
                font-size: 12px;
              }

              button[style*="padding: '8px 15px'"] {
                padding: 6px 12px;
              }
            }
          `}</style>
            </div>
          )}

        </div>
      );
    }



    return <p>Content for {selectedMenu}</p>;

  };

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <HamburgerButton onClick={toggleSidebar}>
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </HamburgerButton>
        <Sidebar ref={sidebarRef} isOpen={isSidebarOpen}>
          <SidebarHeader>Admin Dashboard</SidebarHeader>

          {/* User Greeting Section */}
          <div style={{
            textAlign: "center",
            margin: "15px 0",
            fontSize: "20px",
            fontWeight: "600",
            color: "#FFD700",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "14px",
            padding: "14px 10px",
            borderRadius: "15px",
            background: "linear-gradient(135deg, #4A4A4A, #2D2D2D)",
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.3)",
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
          }} onMouseEnter={(e) => {
            e.target.style.transform = "scale(1.05)";
          }} onMouseLeave={(e) => {
            e.target.style.transform = "scale(1)";
          }}>
            <span style={{ fontWeight: "900", color: "#fff", fontSize: "20px" }}>
              Hello,
              <FaHandsClapping size={24} style={{ color: "#FFD700", marginRight: '10px' }} />
              {personalInfo.fullName
                ? personalInfo.fullName.split(' ')[0].charAt(0).toUpperCase() + personalInfo.fullName.split(' ')[0].slice(1).toLowerCase()
                : "User"
              }
            </span>
          </div>
          {/* Menu Items */}
          <MenuItem
            onClick={() => {
              setSelectedMenu("Business Information");
              setIsSidebarOpen(false); // Close the sidebar on menu item click
            }}
            active={selectedMenu === "Business Information"}
          >
            <FaGear /> Manage Sellers
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedMenu("Personal Information");
              setIsSidebarOpen(false); // Close the sidebar on menu item click
            }}
            active={selectedMenu === "Personal Information"}
          >
            <FaUserCircle /> Personal Profile
          </MenuItem>

          {sessionStorage.getItem("userData") ? (
            // Logout Option
            <MenuItem
              onClick={() => {
                handleLogout();
                setIsSidebarOpen(false); // Close the sidebar on logout
              }}
            >
              <FaSignOutAlt /> Logout
            </MenuItem>
          ) : (
            // Login Option
            <MenuItem
              onClick={() => {
                window.location.href = "/openmp/login"; // Redirect to the login page
                setIsSidebarOpen(false); // Close the sidebar
              }}
            >
              <FaSignInAlt /> Login
            </MenuItem>
          )}

        </Sidebar>

        <Content>{renderContent()}</Content>

        {/* Global Message Box */}
        {showMessageBox && (
          <MessageBox type={messageBoxType}>
            {messageText}
          </MessageBox>
        )}

        {showPersonalEditPopup && (
          <Popup>
            <PopupContent>
              <PopupHeader>
                <h3>Edit Personal Profile</h3>
                <CloseButton onClick={() => setShowPersonalEditPopup(false)}>×</CloseButton>
              </PopupHeader>
              <form>
                <FormField>
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    value={personalInfo.fullName}
                    onChange={handlePersonalFormChange}
                  />
                </FormField>
                <FormField>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={personalInfo.email}
                    onChange={handlePersonalFormChange}
                  />
                </FormField>
                <FormField>
                  <label>Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    value={personalInfo.phone}
                    onChange={handlePersonalFormChange}
                  />
                </FormField>
                <FormActions>
                  <PopupButton type="button" onClick={handlePersonalSaveChanges}>
                    Save
                  </PopupButton>
                  <PopupButton cancel onClick={() => {
                    setShowPersonalEditPopup(false); // Close the popup
                    window.location.reload(); // Reload the page after 1 second
                  }}>
                    Cancel
                  </PopupButton>

                </FormActions>
              </form>
            </PopupContent>
          </Popup>
        )}
      </Wrapper>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  input:-webkit-autofill {
    -webkit-text-fill-color: #333333 !important;  /* Set text color to dark */
    background-color: #f0f0f0 !important;        /* Lighter background for autofill */
    color: #333333 !important;                   /* Ensure the text color is dark */
      height: unset !important;
  }

  input:-webkit-autofill::placeholder {
    color: #888888 !important;   /* Set placeholder text color */
  }
`;

const tableHeaderStyle = {
  border: "1px solid #ddd",
  padding: "12px 18px",
  backgroundColor: "#f0f4f8",
  color: "#333",
  fontWeight: "bold",
  textAlign: "center",
  whiteSpace: "nowrap",
  fontSize: "14px",
  textTransform: "uppercase",
  letterSpacing: "1px",
  borderBottom: "2px solid #ddd",
};

const tableCellStyle = {
  border: "1px solid #ddd",
  padding: "10px 15px",
  textAlign: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  verticalAlign: "middle",
  fontSize: "14px",
};

const SellerProfilesContainer = styled.div`
  margin: 20px auto;
  padding: 20px;
  max-width: 1200px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ResponsiveContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  max-height: 400px; /* Limit the visible height of the table */
  overflow-y: auto; /* Vertical scroll when needed */
  
  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light gray track */
    border-radius: 4px; /* Rounded track edges */
  }

  ::-webkit-scrollbar-thumb {
    background: #888; /* Dark gray thumb */
    border-radius: 4px; /* Rounded thumb edges */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker gray on hover */
  }

  ::-webkit-scrollbar-corner {
    background: #f1f1f1; /* Corner background if needed */
  }
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0; /* Removes the margin to make the table align neatly */
  table-layout: fixed;
  @media (max-width: 760px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

const TableBody = styled.tbody`
  font-size: 14px;
  /* No additional styles needed here */
`;

const ToggleSwitch = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Center the content */
  width: 100%; /* Ensure it fits within the table cell */

  .switch-container {
    position: relative;
    display: inline-block;
    width: 110px; /* Slightly increased width */
    height: 30px; /* Keep the height consistent */
    padding: 0; /* Remove padding to center the switch */
    margin-top: -25%; /* Adjusted margin to bring the switch higher */
    
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    label {
      display: block;
      width: 100%;
      height: 100%;
      background-color: ${({ status }) =>
    status === "approved" ? "#28a745" : "#ffc107"};
      border-radius: 50px; /* Retain the rounded appearance */
      position: relative;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-top:-20px;
  
      .slider {
        position: absolute;
        top: 3px; /* Slightly smaller margin for the slider */
        left: 3px; /* Slightly smaller margin for the slider */
        width: 25px; /* Slightly wider slider */
        height: 25px; /* Slightly higher slider */
        background-color: #fff;
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
    }
  
    input:checked + label {
      background-color: #28a745; /* Green for approved */
      margin-top:-20px;
    }
  
    input:checked + label .slider {
      transform: translateX(75px); /* Adjusted for the slightly larger width */
    }
  }

  .label-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
     font-size: 13px; /* Larger font size */ /* Smaller font size */
    font-weight: bold;
    color: ${({ status }) => (status === "approved" ? "#fff" : "#fff")}; /* White text for contrast */
    text-transform: uppercase;
    pointer-events: none; /* Disable pointer events to allow interaction with the switch */
    padding-right: -30px; /* Added left padding to the pending text */
  }

  .switch-label {
    position: absolute;
    top: 50%;
    font-size: 12px; /* Adjusted font size */
    font-weight: bold;
    color: ${({ status }) => (status === "approved" ? "#fff" : "#fff")};
    text-transform: uppercase;
    left: ${({ status }) => (status === "approved" ? "15px" : "auto")}; /* Adjusted left positioning */
    right: ${({ status }) => (status !== "approved" ? "15px" : "auto")}; /* Adjusted right positioning */
    transform: translateY(-50%);
 
  }
`;

const ActionButton = styled.button`
  padding: 3px 10px; /* Increased padding for a larger button */
  border: 2px solid #007bff; /* Thicker border to match the background */
  border-radius: 40px; /* Larger border-radius for more rounded corners */
  background-color: #007bff;
  color: #fff;
  font-size: 13px; /* Larger font size */
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 8px; /* Increased gap for better spacing */
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3); /* Bigger shadow for a more elevated effect */
  text-transform: uppercase;

  &:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: scale(1.1); /* More prominent scale-up effect */
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.4); /* Deeper shadow on hover */
  }

  &:active {
    background-color: #004085; /* Darker blue when clicked */
    transform: scale(1); /* Return to normal size */
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3); /* Normal shadow when clicked */
  }

  svg {
    font-size: 20px; /* Larger icon for better visibility */
  }
`;

const DisableButton = styled.button`
  padding: 6px 10px; /* Smaller padding for a more compact button */
  border: 1px solid #dc3545; /* Red border for disable action */
  border-radius: 30px; /* Slightly rounded corners for a softer look */
  background-color: #dc3545; /* Red background for disable action */
  color: #fff;
  font-size: 13px; /* Larger font size */ /* Slightly smaller font size */
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 6px;
  box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2); /* Subtle shadow */
  text-transform: uppercase; /* Uppercase text */
  
  &:hover {
    background-color: #c82333; /* Darker red on hover */
    transform: scale(1.05); /* Slight scale-up effect */
    box-shadow: 0 4px 8px rgba(220, 53, 69, 0.3); /* Deeper shadow on hover */
  }

  &:active {
    background-color: #bd2130; /* Darker red when clicked */
    transform: scale(1); /* Return to normal size */
    box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2); /* Normal shadow when clicked */
  }

  svg {
    font-size: 16px; /* Slightly larger icon for better visibility */
  }
`;

const ArchivedText = styled.div`
  padding: 3px 10px; /* Smaller padding for a more compact appearance */
  border: 1px solid #6c757d; /* Gray border for archived status */
  border-radius: 30px; /* Slightly rounded corners */
  background-color: #6c757d; /* Muted gray background for archived status */
  color: #fff; /* White text for readability */
  font-size: 13px; /* Slightly smaller font size */
  font-weight: bold;
  text-align: center; /* Center the text */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 4px rgba(108, 117, 125, 0.2); /* Subtle shadow for depth */
  text-transform: uppercase;

  svg {
    font-size: 16px; /* Slightly larger icon for visibility */
  }

  &:hover {
    background-color: #5a6268; /* Darker gray on hover */
    transform: scale(1.05); /* Slight scale-up effect */
    box-shadow: 0 4px 8px rgba(108, 117, 125, 0.3); /* Deeper shadow on hover */
  }

  &:active {
    background-color: #545b62; /* Darker gray when clicked */
    transform: scale(1); /* Return to normal size */
    box-shadow: 0 2px 4px rgba(108, 117, 125, 0.2); /* Normal shadow when clicked */
  }
`;

const DisabledText = styled.div`
  padding: 3px 10px; /* Smaller padding for a more compact appearance */
  border: 1px solid #ccc; /* Light gray border for disabled status */
  border-radius: 30px; /* Slightly rounded corners */
  background-color: #ccc; /* Light gray background for disabled status */
  color: #fff; /* White text for readability */
  font-size: 13px; /* Slightly smaller font size */
  font-weight: bold;
  text-align: center; /* Center the text */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 4px rgba(204, 204, 204, 0.2); /* Subtle shadow for depth */
  text-transform: uppercase;

  svg {
    font-size: 16px; /* Slightly larger icon for visibility */
  }

  &:hover {
    background-color: #b0b0b0; /* Darker gray on hover */
    transform: scale(1.05); /* Slight scale-up effect */
    box-shadow: 0 4px 8px rgba(204, 204, 204, 0.3); /* Deeper shadow on hover */
  }

  &:active {
    background-color: #999; /* Darker gray when clicked */
    transform: scale(1); /* Return to normal size */
    box-shadow: 0 2px 4px rgba(204, 204, 204, 0.2); /* Normal shadow when clicked */
  }
`;

const NoData = styled.p`
  text-align: center;
  font-size: 16px;
  color: #888;
  width:400px;
  padding: 15px;
  border: 1px dashed #ccc;
  background-color: #f9f9f9;
  margin-top: 20px;
  font-style: italic; /* Make it more distinct */
  letter-spacing: 0.5px;
`;

const MessageBox = styled.div`
  background-color: ${(props) =>
    props.type === "success" ? "green" :
      props.type === "error" ? "red" :
        props.type === "info" ? "red" : "gray"};
  color: white;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 300px;
  text-align: center;
  border-radius: 5px;
  z-index: 9999;
  font-size: 16px;
`;

const CardContainer = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default to 2 columns for larger screens */
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr); /* Single column for tablets and smaller screens */
    gap: 20px;
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Stack cards for small mobile screens */
    gap: 10px;
    padding: 0 10px;
  }
`;

const Card = styled.div`
  background: #fff;
  position: relative;
  border-radius: 20px; /* Slightly rounded corners for a more modern feel */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Softer, larger shadow for depth */
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  background: linear-gradient(145deg, #f1f1f1, #e0e0e0); /* Subtle gradient background for the card */

  &:hover {
    transform: translateY(-8px); /* Slight lift on hover */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Larger shadow for hover effect */
    background: linear-gradient(145deg, #ffffff, #f4f4f4); /* Hover effect with subtle color change */
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Adjust max-width for medium screens */
  }

  @media (max-width: 480px) {
    max-width: 100%; /* Full width on very small screens */
    margin: 10px 0; /* Reduce margin for better spacing */
  }
`;

const CardContent = styled.div`
  padding: 5px 30px;
  background-color: #ffffff; /* Clean white background for content */
  display: flex;
  flex-direction: column;
  gap: 1px;
  flex: 1; 
  color: #666; /* Softer text color for content */
`;

const CardHeader = styled.div`
  padding: 15px;
  background-color: #bde4e85e; /* Soft background color for the header */
  border-bottom: 1px solid #ddd; /* Border to separate the header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px 20px 0 0; /* Round top corners for the header */
`;

const CardTitle = styled.h3`
  font-size: 19px;
  font-weight: 600;
  color: #333; /* Darker text for readability */
  margin: 0;
  display: flex;
  align-items: center; /* Align icon and text at the same height */
  gap: 10px; /* Space between icon and text */
  word-wrap: break-word; /* Prevents overflow for long titles */
  line-height: 1.2; /* Adjust line height for better readability */

  svg {
    flex-shrink: 0; /* Prevent icon from resizing */
    font-size: 1.5em; /* Adjust icon size to match text height */
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    font-size: 16px; /* Smaller font size for tablets */
    svg {
      font-size: 1.3em; /* Adjust icon size */
    }
  }

  @media (max-width: 480px) {
    font-size: 14px; /* Smallest font size for mobile */
    svg {
      font-size: 1.2em; /* Adjust icon size */
    }
  }
`;

const EditButton = styled.button`
  background-color: #66bb6a; /* Subtle green */
  color: white;
  border: none;
  padding: 10px 15px; /* Comfortable padding for a sleek look */
  border-radius: 30px; /* Rounded corners for an elegant touch */
  cursor: pointer;
  margin-right: -5px;
  font-size: 14px;
  font-weight: 500; /* Semi-bold for readability */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #81c784; /* Lighter shade on hover */
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(1px); /* Slight push when clicked */
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increased gap for better spacing between items */
  padding: 0 20px; /* Added horizontal padding for spacing from edges */
  width: 100%; /* Ensure it takes up the full width of the card */
`;

const CardItem = styled.div`
 display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
`;

const CardIcon = styled.div`
font-size: 18px;
 color: #007bff; /* Vibrant color for icons */
`;

const UserLogo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0; /* Reduced margin */
  padding: 0; /* Remove any unnecessary padding */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Sidebar = styled.div`
  width: ${(props) => (props.isOpen ? "250px" : "0")};
  height: 100vh;
  background: #2b2b2b;
  color: white;
  transition: width 0.3s ease-in-out;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media (min-width: 1024px) {
    width: 250px;
    position: static;
    height: auto;
    flex-direction: column;
    transition: none;
  }
`;

const SidebarHeader = styled.div`
  background: linear-gradient(135deg, #1f1f1f, #333); /* Gradient for depth */
  color: #FFD700; /* Gold color for a bold and modern feel */
  padding: 10px;
  text-align: center;
  font-size: 24px; /* Increased font size for better visibility */
  font-weight: 600; /* Slightly lighter weight for a cleaner look */
  border-bottom: 2px solid #444;
  text-transform: uppercase; /* Adds a more refined touch */
  letter-spacing: 1px; /* Slight letter spacing for more elegance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: all 0.3s ease;

  /* Hover effect */
  &:hover {
    background: linear-gradient(135deg, #333, #1f1f1f); /* Reverse gradient on hover */
    transform: scale(1.02); /* Slight zoom effect */
  }
`;

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-grow: 1;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: green;
  font-size: 30px;
  z-index: 1001;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const MenuItem = styled.div`
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => (props.active ? "#4CAF50" : "white")};
  background: ${(props) => (props.active ? "#222" : "none")};
  transition: all 0.3s ease;
  &:hover {
    background: #555;
    color: #FFD700; // Golden Yellow on hover
  }
`;

const Content = styled.div`
  flex-grow: 1;
  margin-left: ${(props) => (props.isSidebarOpen ? "250px" : "0")};
  transition: margin-left 0.3s ease-in-out;
  padding: 20px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #d9534f;
  }
`;

const FormField = styled.div`
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;

    color: #333;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
    }
  }
`;

const FormActions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

const PopupButton = styled.button`
  background: ${(props) => (props.cancel ? "#ccc" : "#007bff")};
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  &:hover {
    background: ${(props) => (props.cancel ? "#999" : "#0056b3")};
  }
`;

const PopupContent = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;
