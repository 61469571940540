import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "/blockchain/vmdata/openmarket/demo/ui/src/assets/BGV/svg/CloseIcon.jsx";
import FujitsuLogo from "/blockchain/vmdata/openmarket/demo/ui/src/assets/Marketplace/img/projects/fj-footer.png";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <>
      <GlobalStyle />
      <Wrapper sidebarOpen={sidebarOpen}>
        <SidebarHeader>
          <LogoWrapper>
            <img src={FujitsuLogo} alt="Fujitsu Logo" />
          </LogoWrapper>
          <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)}>
            <CloseIcon />
          </CloseBtn>
        </SidebarHeader>

        <NavList>
          <li>
            <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/rclanding" target="_top" rel="noopener noreferrer">
              Home
            </a>
          </li>
          <li>
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              to="services"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              to="projects"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Users
            </Link>
          </li>
          <li>
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              to="contacts"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Contact
            </Link>
          </li>
        </NavList>

        <NavFooter>
          <li>
            <a href="https://aet-blockchain.centralindia.cloudapp.azure.com/rclanding" className="radius8">
              Get Started
            </a>
          </li>
        </NavFooter>
      </Wrapper>
    </>
  );
}

// Styled Components
const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Disable horizontal scrolling */
  }

  html {
    width: 100%;
    overflow-x: hidden;
  }
`;

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  right: ${(props) => (props.sidebarOpen ? "0" : "-100%")};
  width: 100%;
  max-width: 400px;
  height: 100vh;
  background: #000;
  color: white;
  z-index: 9999;
  transition: right 0.3s ease;
  overflow-y: auto;
  padding: 20px;

  /* Prevent horizontal scroll */
  overflow-x: hidden;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: center; /* Center vertically */
  gap: 10px; /* Space between logo and hamburger icon */
  padding: 10px 0;

  @media (max-width: 480px) {
    gap: 5px;
    padding: 5px 0;
  }
`;

const LogoWrapper = styled.div`
  img {
    max-width: 100px;
    height: auto;
  }

  @media (max-width: 480px) {
    img {
      max-width: 80px;
    }
  }
`;

const CloseBtn = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;

  @media (max-width: 480px) {
    padding: 0;
  }
`;

const NavList = styled.ul`
  list-style: none;
  padding: 20px 0;

  li {
    margin: 20px 0;
    a {
      text-decoration: none;
      color: white;
      font-size: 16px;
      padding: 10px 15px;
      display: block;

      &:hover {
        color: #eb189c;
      }
    }
  }
`;

const NavFooter = styled.ul`
  list-style: none;
  padding: 20px 0;

  li {
    a {
      text-decoration: none;
      color: white;
      background: #eb189c;
      padding: 10px 15px;
      border-radius: 8px;
      display: inline-block;

      &:hover {
        background: white;
        color: #eb189c;
      }
    }
  }
`;
